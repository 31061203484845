<template>
  <div class="settings">
    <div v-if="$store.getters.getPreloaderStatus" key="preloader" id="loadingProgressG">
      <div class="loadingProgressG"></div>
    </div>
    <div v-else key="content">
      <form @submit.prevent="saveUser" id="userForm">
        <div class="form-group">
          <div class="description">
			  {{$t('users.name_abbreviated')}}
          </div>
          <div class="input-wrapper">
            <input class="input"
                   required
                   :placeholder="$t('users.enter_name_desc')" v-model="name" />
          </div>
        </div>
        <div class="form-group mt-20">
          <div class="description">
            Email
          </div>
          <div class="input-wrapper">
            <input class="input"
                   required
                   :placeholder="$t('users.enter_email_desc')"
                   v-model="email"/>
          </div>
        </div>

        <div class="form-group mt-20">
			<div class="description">
				{{$t('common.language')}}
			</div>
			<div class="input-wrapper">
				<languageSelector />
			</div>
        </div>
      </form>

      <div class="d-flex justify-between mt-20">
        <button type="submit" form="userForm" class="btn red floating">
			{{$t('common.save')}}
        </button>
        <button class="btn transparent red" @click="logout">
			{{$t('users.logout')}}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "../../i18n";
import languageSelector from "../../components/language_selector";

  export default {
    name: "index",
    data () {
      return {
        name: null,
        email: null,
		langs: [].concat(i18n.langs),
      }
    },
    methods: {
      async saveUser () {
		i18n.saveLocale(i18n.locale);
        try {
          await this.$store.dispatch('backoffRequest', {
            url: '/v1/factory/users/me',
            method: 'PUT',
            data: {
              fio: this.name,
              email: this.email
            }
          })
          this.$store.dispatch('showNotice', {
            title: this.$t('common.success'),
            message: this.$t('common.data_saved'),
            icon: 'warning',
            time: 5000
          })
        } catch(e) {
          console.log(e)
        }
      },
      logout () {
        this.$store.commit('logout')
        this.$router.push('/')
      }
    },
    async created () {
      this.$store.commit('setPreloader', true)
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          url: '/v1/factory/users/me',
          method: 'GET'
        })
        this.name = resp.data.info.fio
        this.email = resp.data.info.email
        this.$store.commit('setPreloader', false)
      } catch(e) {
        console.log(e)
      }
    },
	setLocale(l) {
		console.log(i18n);
		console.log(i18n.setLocale);
		i18n.setLocale(l);
	},
	components: {
		languageSelector,
	}
  }
</script>
