<template>
  <div class="login">
    <div class="panel">
      <img class="logo" src="../../assets/images/authentication/main_logo.svg" alt="" />
	  <div class="form-box">
		  <form id="login" @submit.prevent="login" autocomplete="off">
			<div class="input-wrapper mb-20">
			  <input
				class="input inverted"
				:placeholder="$t('auth.login')"
				type="email"
				required
				autocomplete="nope"
				v-model="username"/>
			  <div class="helper-wrapper">
				  <span>{{$t('auth.login_incorrect')}}</span>
				<img src="../../assets/images/components/input/warning.svg" alt="" />
			  </div>
			</div>
			<div class="input-wrapper">
			  <input
				class="input with-icon inverted"
				:placeholder="$t('auth.password')"
				required
				:type="type" v-model="password">
			  <img :class="[type === 'text' ? 'd-none' : '']" src="../../assets/images/authentication/eye_open.svg" class="icon" @click="changePassType('text')" alt="" />
			  <img :class="[type === 'password' ? 'd-none' : '']" src="../../assets/images/authentication/eye_closed.svg" class="icon" @click="changePassType('password')" alt="" />
			  <img src="" alt="" />
			  <div class="helper-wrapper">
				<span></span>
			  </div>
			</div>

			<button
			  :disabled="!username || !password"
			  class="btn red floating mb-20" type="submit">
				{{$t('auth.login_button')}}
			</button>
		  </form>
		  <languageSelector hide-names="true" dark="true"/>
	  </div>
    </div>
  </div>
</template>

<script>
import languageSelector from "../../components/language_selector";
import i18n from "../../i18n";

  export default {
    name: "login",
    data () {
      return {
        username: '',
        password: '',
        type: 'password'
      }
    },
    methods: {
      changePassType (type) {
        this.type = type
      },
      async login () {
		  i18n.saveLocale(i18n.locale);

        try {
          let resp = await this.$store.dispatch('backoffRequest', {
            url: '/v1/auth/admin/signin',
            method: 'POST',
            data: {
              email: this.username,
              password: this.password
            }
          })
          this.$store.commit('setToken', resp.data.token)
          this.$store.commit('setPermissions', resp.data.user.permissions)
          this.$store.commit('setFactoryName', resp.data.user['factory_name'])
		  this.$store.commit('setFactoryId', resp.data.user['factory_id'])
          this.$store.commit('setUserName', resp.data.user.fio)
          this.$router.push({name: 'ProductionList'})
        } catch (e) {
          let message
          if(e.response && e.response.data.message) {
            message = e.response.data.message
          } else {
            message = e.message
          }
          this.$store.dispatch('showNotice', {
            title: this.$t('common.attention'),
            message: message,
            icon: 'warning',
            time: 4000
          })
          this.$store.commit('logout')
        }
      }
    },
	components: {
		languageSelector,
	}
  }
</script>
