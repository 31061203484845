<template>
  <div class="content">
    <transition mode="out-in" name="fade">
      <div v-if="$store.getters.getPreloaderStatus" key="preloader" id="loadingProgressG">
        <div class="loadingProgressG"></div>
      </div>
      <div v-else class="users" key="content">
        <div class="contacts">
          <div class="table">
            <el-table
                    @row-click="rowClick"
                    :data="dataTable"
                    :empty-text="$t('users.no_users')"
                    :header-row-class-name="tableHeader()">
              <el-table-column :label="$t('users.field_headers.name')" prop="fio" min-width="200">
                <template slot-scope="data">
              <span class="ubuntu-regular">
                {{ data.row ? nameConversion(data.row.fio) : ''}}
              </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('users.field_headers.role')" prop="role" min-width="150">
                <template slot-scope="data">
              <span class="ubuntu-medium">
                {{data.row ? roleConversion(data.row.role) : ''}}
              </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="table-footer" v-if="pagination.total > 15">
            <div class="font-14 ubuntu-regular text-gray">
                {{ currentOffset }} of {{pagination.total}}
            </div>
            <el-pagination
                    :background="false"
                    :page-size="pagination.perPage"
                    :total="pagination.total"
                    :current-page="pagination.current"
                    layout="prev, next"
                    @current-change="changePage"
            ></el-pagination>
          </div>
        </div>

        <div class="user">
          <transition name="fade" mode="out-in">
			  <div v-if="$route.name === 'UserList'">
				  <div class="binding-barcode">
					  <span>{{$t('users.qa_app_binding_code')}}</span>
					  <div id="factory-barcode" v-html="svgnode"/>
				  </div>
				  <div class="create-user-button">
					  <create-button :key="'createButton'"></create-button>
				  </div>
			  </div>
            <router-view v-else :key="$route.params.id"></router-view>
          </transition>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import createButton from './createButton';
  const DATAMatrix = require("../../util/datamatrix");

  export default {
    name: "index",
    data () {
      return {
        pagination: {
          current: 1,
          perPage: 15,
          total: 0
        },
		dataTable: [],
		svgnode: "",
      }
    },
    computed: {
      currentOffset: function () {
        let start = (this.pagination.current - 1) * this.pagination.perPage + 1
        let end = ''
        if (this.pagination.current * this.pagination.perPage < this.pagination.total) {
          end = '- ' + this.pagination.current * this.pagination.perPage
        } else if (this.dataTable.length < this.pagination.perPage && this.dataTable.length > 1) {
          end = '- ' + this.pagination.total
        }
        return `${start} ${end}`
      }
    },
    watch: {
      '$route': async function (value) {
        if(value.name === 'UserList') {
          try {
            let resp = await this.$store.dispatch('backoffRequest', {
              url: `/v1/factory/users?page=${this.pagination.current - 1}&limit=${this.pagination.perPage}`,
              method: 'GET',
            })
            this.dataTable = resp.data.users
            this.pagination.total = resp.data.total
          } catch (e) {
            console.log(e)
          }
        }
      }
    },
    methods: {
      tableHeader () {
        return 'secondary-table-header'
      },
      async changePage (page) {
        this.pagination.current = page
        try {
          let resp = await this.$store.dispatch('backoffRequest', {
            url: `/v1/factory/users?page=${this.pagination.current - 1}&limit=${this.pagination.perPage}`,
            method: 'GET'
          })
          this.dataTable = resp.data.users
          this.pagination.total = resp.data.total
        } catch(e) {
          console.log(e)
        }
        this.pagination.current = page
      },
      nameConversion (name) {
        let arr = name.split(' ')
        arr.forEach((value, index, arr) => {
          if (index >= 1) {
            arr[index] = value[0] + '.'
          }
        })
        return arr.join(' ')
      },
      roleConversion (role) {
        switch (true) {
          case role === 'factory_admin':
            return this.$t('users.roles.factory_admin')
          case role === 'otk':
            return this.$t('users.roles.otk')
        }
      },
      rowClick(row) {
        if (this.$route.params.id !== row.uuid) {
          this.$router.push({name: 'UserRead', params: {id: row.uuid}})
        }
      }
    },

    async created () {
      this.$store.commit('setPreloader', true)
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          url: `/v1/factory/users?page=${this.pagination.current - 1}&limit=${this.pagination.perPage}`,
          method: 'GET',
        })
        this.dataTable = resp.data.users
        this.pagination.total = resp.data.total
        this.$store.commit('setPreloader', false)
      } catch (e) {
        console.log(e)
      }
    },

	mounted() {
		// generate binding datamatrix
		const data = {
			uuid: this.$store.getters.getFactoryId,
			name: this.$store.getters.getFactoryName,
		}
		const datamatrix_text = JSON.stringify(data).replace(/[\u007F-\uFFFF]/g, (chr) => {
			return "\\u" + ("0000" + chr.charCodeAt(0).toString(16)).substr(-4)});
		const pad = 5;
		const svgnode = DATAMatrix({
			msg: datamatrix_text,
			dim : 180,	// dimensions
			rct : 0,	// keep datamatrix square
			pad,	// padding
			pal : ["#000000", "#f8f9fb"],	// pallette
			vrb : 1,	// verbose
		});
		const e = document.getElementById("factory-barcode");
		if(e) {
			while(e.childNodes[0]) {
				e.removeChild(e.childNodes[0]);
			}
			e.appendChild(svgnode);
		}
		this.svgnode = svgnode.outerHTML;
	},

    components: {
      createButton
    }
  }
</script>

<style scoped lang="scss">
  button.btn {
    width: 100%;
    max-width: 250px;
  }
</style>
