<template>
  <div class="notification">
    <div class="bell-wrapper" @click="toggleNotification">
      <img v-if="$store.getters.isNotificationOpen"
           class="bell" src="../../assets/images/components/notification/bell_active.svg" alt="" />
      <img v-else
           class="bell" src="../../assets/images/components/notification/bell.svg" alt="" />
      <div v-if="!$store.getters.isNotificationOpen && $store.getters.getNotificationMessages.length
                  && $store.getters.getNotificationMessages[0].dummy !== true"
           class="bell-dot"></div>
    </div>
    <div v-if="$store.getters.isNotificationOpen" class="triangle"></div>
    <div :class="[$store.getters.isNotificationOpen ? 'active' : '']"
         class="dropdown">
      <div v-for="(message, index) in $store.getters.getNotificationMessages" :key="index + 'B'" class="dropdown-item">
        <img class="letter" src="../../assets/images/components/notification/letter.svg">
        <div class="content">
          <div class="message-wrapper">
            <span class="title">{{message.title}}</span>
			{{!message.dummy ? message.action : $t('layout.no_new_notifications')}}
          </div>
          <div class="data-wrapper" v-if="message.time">
            {{ new Date(message.time).toLocaleDateString().split('.').reverse().join('-')}}
            <span class="time">
              {{ new Date(message.time).toLocaleTimeString().split(':').slice(0,2).join(':') }}
              ({{getTimeZone(message.time)}})
            </span>
          </div>
        </div>
        <div v-if="index !== $store.getters.getNotificationMessages.length - 1" class="dropdown-divider"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "notification",
  watch: {
    '$route': function() {
      this.getNotifications()
    }
  },
  methods: {
    toggleNotification () {
      this.$store.getters.isNotificationOpen ? this.$store.commit('closeNotification') : this.$store.commit('openNotification')
    },
    getTimeZone (time) {
      time = new Date(time).getTimezoneOffset()
      time /= 60
      if (time % 1 != 0) {
        time = Math.trunc(time)
      }
      if (time >= 0) {
        time = '-' + time + ':00'
      } else {
        time = '+' + Math.abs(time) + ':00'
      }
      return time
    },
    async getNotifications () {
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          url: '/v1/verify/token',
          method: 'POST',
          data: {
            token: this.$store.getters.getToken
          }
        })
        if (resp.data.notifications.rows.length) {
          let notifications = resp.data.notifications.rows
          let formatedArray = []
          notifications.forEach(value => {
            formatedArray.push({
              title: '',
              action: value.message,
              time: value.created
            })
          })
          this.$store.commit('setNotifications', formatedArray)
          console.log('and here')
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  created () {
    this.getNotifications()
  }
}
</script>
