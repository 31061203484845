<template>
  <div class="finance">
    <transition name="fade" mode="out-in">
      <div v-if="$store.getters.getPreloaderStatus" key="preloader" id="loadingProgressG">
        <div class="loadingProgressG"></div>
      </div>
      <el-row v-else key="content" :gutter="20">
        <el-col :sm="24" :md="12" class="panel left">
          <el-tabs v-model="currentTab" type="card">
            <el-tab-pane label="Счета" name="bills">
              <el-table :data="billTable"
                        empty-text="Нет данных"
                        :header-row-class-name="tableHeader">
                <el-table-column label="№ Счёта" min-width="150" align="center">
                  <template slot-scope="data">
                    <span class="ubuntu-regular font-15">
                      {{data.row ? data.row.id : ''}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Сформирован" min-width="150" align="center">
                  <template slot-scope="data">
                    <span class="ubuntu-regular font-15">
                      {{ data.row ? new Date(data.row.created).toLocaleDateString() : ''}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Скачать" min-width="100" align="center">
                  <template slot-scope="data">
                    <a v-if="data.row" :href="data.row.link"  target="_blank">
                      <img class="ml-auto mr-auto" src="../../assets/images/finance/pdf_icon.svg" alt="">
                    </a>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-footer" v-if="billPagination.total > 15">
                <div class="font-14 ubuntu-regular text-gray">
                  {{ currentOffset }} of {{billPagination.total}}
                </div>
                <el-pagination
                        :background="false"
                        :page-size="billPagination.perPage"
                        :total="billPagination.total"
                        :current-page="billPagination.current"
                        layout="prev, next"
                        @current-change="changePage"></el-pagination>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Акты" name="acts">
              <el-table :data="actTable"
                        empty-text="Нет данных"
                        :header-row-class-name="tableHeader">
                <el-table-column label="№ Акта" min-width="150" align="center">
                  <template slot-scope="data">
                    <span class="ubuntu-regular font-15">
                      {{data.row ? data.row.id : ''}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Сформирован" min-width="150" align="center">
                  <template slot-scope="data">
                    <span class="ubuntu-regular font-15">
                      {{ data.row ? new Date(data.row.created).toLocaleDateString() : ''}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Скачать" min-width="100" align="center">
                  <template slot-scope="data">
                    <a v-if="data.row" :href="data.row.link" target="_blank">
                      <img class="ml-auto mr-auto" src="../../assets/images/finance/pdf_icon.svg" alt="">
                    </a>
                  </template>
                </el-table-column>
              </el-table>
              <div class="table-footer" v-if="actPagination.total > 15">
                <div class="font-14 ubuntu-regular text-gray">
                  {{ currentOffset }} of {{actPagination.total}}
                </div>
                <el-pagination
                        :background="false"
                        :page-size="actPagination.perPage"
                        :total="actPagination.total"
                        :current-page="actPagination.current"
                        layout="prev, next"
                        @current-change="changePage"></el-pagination>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-col>
        <el-col :sm="24" :md="12" class="panel right">
          <button v-if="$store.getters.getPermission('finance-report')" @click="createAct" class="btn red floating transparent">Заказать акт сверки</button>
          <div class="mb-20">
            <div class="d-flex align-center mb-10">
              <img src="../../assets/images/finance/barcode.svg" alt="" class="price-icon mr-10">
              <span class="ubuntu-medium font-16 mr-5 nowrap">{{price.barcode}} &#8381;</span>
              <span class="ubuntu-regular font-16 text-gray"> / Код на поверхности (или внутреннем элементе) продукции</span>
            </div>
            <div class="d-flex align-center mb-10">
              <img src="../../assets/images/finance/datamatrix.svg" alt="" class="price-icon mr-10">
              <span class="ubuntu-medium font-16 mr-5 nowrap">{{price.datamatrix}} &#8381;</span>
              <span class="ubuntu-regular font-16 text-gray"> / Код на ленте</span>
            </div>
          </div>
          <div class="balance-wrapper">
            <div class="ubuntu-medium font-16 mb-20">Пополнение баланса</div>
            <div class="d-flex align-center mb-20">
              <div class="description">Код на поверхности <br/> (или внутреннем элементе) продукции (шт):</div>
              <div class="value input-wrapper">
                <input class="input" type="number" step="1" min="0" v-model="outerCodes"/>
              </div>
            </div>
            <div class="d-flex align-center mb-20">
              <div class="description">Коды на ленте (шт):</div>
              <div class="value input-wrapper">
                <input class="input" type="number" step="1" min="0" v-model="innerCodes"/>
              </div>
            </div>
            <div class="d-flex align-center mb-20">
              <div class="description">Сумма:</div>
              <div class="value input-wrapper">
                <input class="input" type="number" step="1" min="0" readonly v-model="cost"/>
                <span class="font-14 text-gray">конечная стоимость указана с учетом НДС</span>
              </div>
            </div>
            <button class="btn red floating" @click="refill">Пополнить баланс на {{cost}} руб.</button>
          </div>
        </el-col>
      </el-row>
    </transition>
  </div>
</template>

<script>
export default {
  name: "index",
  data () {
    return {
      currentTab: 'bills',
      outerCodes: null,
      innerCodes: null,
      billTable: [],
      billPagination: {
        current: 1,
        perPage: 15,
        total: 16
      },
      price: {
        barcode: 0,
        datamatrix: 0
      },
      actTable: [],
      actPagination: {
        current: 1,
        perPage: 15,
        total: 16
      }
    }
  },
  computed: {
    cost: function () {
      return (this.outerCodes * this.price.barcode + this.innerCodes * this.price.datamatrix).toFixed(2)
    },
    currentOffset: function () {
      let pagination
      let table
      if (this.currentTab === 'bills') {
        pagination = 'billPagination'
        table = 'billTable'
      } else {
        pagination = 'actPagination'
        table = 'actTable'
      }
      let start = (this[pagination].current - 1) * this[pagination].perPage + 1
      let end = ''
      if (this[pagination].current * this[pagination].perPage < this[pagination].total) {
        end = '- ' + this[pagination].current * this[pagination].perPage
      } else if (this[table].length < this[pagination].perPage && this[table].length > 1) {
        end = '- ' + this[pagination].total
      }
      return `${start} ${end}`
    }
  },
  methods: {
    tableHeader () {
      return 'secondary-table-header'
    },
    changePage (page) {
      let pagination
      this.currentTab === 'bills' ? pagination = 'billPagination' : pagination = 'actPagination'
      this[pagination].current = page
      switch (true) {
        case this.currentTab === 'bills':
          this.getBillData()
          break
        case this.currentTab === 'acts':
          this.getActData()
          break
      }
    },
    async createAct() {
      try {
        await this.$store.dispatch('backoffRequest', {
          url: '/v1/factory/finances/report',
          method: 'POST'
        })
        this.$store.dispatch('showNotice', {
          title: 'Внимание',
          message: 'Акт сверки будет направлен на ваш email адрес.',
          icon: 'warning',
          time: 4000
        })
      } catch(e) {
        console.log(e)
      }
    },
    async refill() {
      try {
        await this.$store.dispatch('backoffRequest', {
          url: '/v1/factory/finances/bill',
          method: 'POST',
          data: {
            amount: this.cost
          }
        })
        this.$store.dispatch('showNotice', {
          title: 'Внимание',
          message: 'Счет на оплату сформирован',
          icon: 'warning',
          time: 4000
        })
        this.getBillData()
      } catch(e) {
        console.log(e)
      }
    },
    async getBillData() {
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          method: 'POST',
          url: '/v1/auth/admin/download_token',
        });
        const token = resp.data.token;

        resp = await this.$store.dispatch('backoffRequest', {
          method: 'GET',
          url: '/v1/factory/finances/bills',
          params: {
            limit: this.billPagination.perPage,
            page: this.billPagination.current - 1
          }
        })

        resp.data.rows.map((i) => {
            if(i.link == null) {
                i.link = `${process.env.VUE_APP_BASE_URL}/v1/factory/finances/bills/${token}/${i.id}`;
            }
        });

        this.billTable = resp.data.rows
        this.billPagination.total = resp.data.count
      } catch(e) {
        console.log(e)
      }
    },
    async getActData() {
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          method: 'POST',
          url: '/v1/auth/admin/download_token',
        });
        const token = resp.data.token;

        resp = await this.$store.dispatch('backoffRequest', {
          method: 'GET',
          url: '/v1/factory/finances/acts',
          params: {
            limit: this.actPagination.perPage,
            page: this.actPagination.current - 1
          }
        })

        resp.data.rows.map((i) => {
            if(i.link == null) {
                i.link = `${process.env.VUE_APP_BASE_URL}/v1/factory/finances/acts/${token}/${i.id}`;
            }
        });

        this.actTable = resp.data.rows
        this.actPagination.total = resp.data.count
      } catch(e) {
        console.log(e)
      }
    },
    async getPrice() {
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          url: '/v1/factory/finances/prices',
          method: 'GET'
        })
        Object.assign(this.price, resp.data)
      } catch(e) {
        console.log(e)
      }
    }
  },
  async created () {
    this.$store.commit('setPreloader', true)
    try {
      await this.getBillData()
      await this.getActData()
      await this.getPrice()
      this.$store.commit('setPreloader', false)
    } catch(e) {
      console.log(e)
    }
  }
}
</script>
