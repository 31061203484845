<template>
  <div class="user-create">
	  <div class="user-title">{{$t('users.creating_new_user')}}</div>
    <div class="user-wrapper">
      <div class="data">
        <div class="form-group">
			<div class="description">{{$t('users.field_headers.name')}}</div>
          <div class="input-wrapper">
			  <input
					  :placeholder="$t('users.enter_name_desc')"
					  class="input" v-model="user.fio"/>
          </div>
        </div>
        <transition mode="out-in" name="fade">
          <div class="form-group" v-if="user.role.value === 'factory_admin'">
            <div class="description">E-mail</div>
            <div class="input-wrapper">
              <input
                     :placeholder="$t('users.enter_email_desc')"
                     class="input" v-model="user.email"/>
            </div>
          </div>
        </transition>
        <div class="form-group">
			<div class="description">{{$t('users.field_headers.role')}}</div>
          <el-dropdown
                  placement="bottom-start"
                  @visible-change="dropdownAppear"
                  trigger="click">
          <span class="el-dropdown-link">
            {{user.role.label}}
          </span>
            <el-dropdown-menu>
              <el-dropdown-item v-for="role in dropdown" :key="role.label">
                <span class="item" @click="changeRole(role)">{{role.label}}</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="form-group">
          <div class="description">
			  {{$t('users.password')}}
          </div>
          <div class="input-wrapper" v-if="user.password">
            <input v-model="user.password" readonly class="text-red ubuntu-medium input" />
          </div>
          <div v-else class="text-red pointer ubuntu-medium font-14" @click="passGen">
			  {{$t('users.generate_password')}}
		  </div>
        </div>
      </div>
      <transition mode="out-in" name="fade">
        <div v-if="user.role.value === 'factory_admin'" class="checkboxes">
			<span class="ubuntu-medium font-16">{{$t('users.access_permissions')}}:</span>
          <div v-if="$store.getters.getPermission('analytics')" class="d-flex mt-20">
            <div class="checkbox mr-10">
              <input id="analytic" type="checkbox" v-model="rights.analytics">
              <label for="analytic"></label>
            </div>
			<label class="pointer" for="analytic">{{$t('users.permissions.analytics')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('advert')" class="d-flex mt-5">
            <div class="checkbox mr-10">
              <input id="advertising" type="checkbox" v-model="rights.advert">
              <label class="pointer" for="advertising"></label>
            </div>
            <label for="advertising">{{$t('users.permissions.advertisements')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('users')" class="d-flex mt-5">
            <div class="checkbox mr-10">
              <input id="users" type="checkbox" v-model="rights.users">
              <label for="users"></label>
            </div>
            <label class="pointer" for="users">{{$t('users.permissions.users')}}</label>
          </div>

          <div v-if="$store.getters.getPermission('products')" class="d-flex mt-5">
            <div class="checkbox mr-10">
              <input id="production" type="checkbox" v-model="rights.products">
              <label for="production"></label>
            </div>
            <label class="pointer" for="production">{{$t('users.permissions.products')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('products.edit')" class="d-flex mt-5 ml-20">
              <div class="checkbox mr-10">
                  <input id="production_edit" type="checkbox" v-model="rights.productsEdit" class="display-none">
                  <label for="production_edit"></label>
              </div>
              <label class="pointer" for="production_edit">{{$t('users.permissions.products_edit')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('products.release')" class="d-flex mt-5 ml-20">
              <div class="checkbox mr-10">
                  <input id="production_release" type="checkbox" v-model="rights.productsRelease" class="display-none">
                  <label for="production_release"></label>
              </div>
              <label class="pointer" for="production_edit">{{$t('users.permissions.products_release')}}</label>
          </div>

          <div v-if="$store.getters.getPermission('finances')" class="d-flex mt-5">
            <div class="checkbox ml-10">
              <input id="finance" type="checkbox" v-model="rights.finances">
              <label for="finance"></label>
            </div>
            <label class="pointer" for="finance">{{$t('users.permissions.finances')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('finance-report')" class="d-flex ml-20 mt-5">
            <div class="checkbox mr-10">
              <input id="acts" type="checkbox" v-model="rights.financeReport">
              <label for="acts"></label>
            </div>
            <label class="pointer"
				for="acts">{{$t('users.permissions.reconciliation_report')}}</label>
          </div>
        </div>
      </transition>
      <div class="buttons">
        <button class="btn transparent red" @click="cancel">{{$t('common.cancel')}}</button>
		<button class="btn floating red"
				@click="createUser">{{$t('users.create_user')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
// TODO share code with read.vue
  import pc from 'password-creator'
  export default {
    name: "create",
    data () {
      return {
        user: {
          fio: null,
          email: null,
          role: { label: 'OTK', value: 'otk' },
          password: null
        },
        rights: {
          analytics: false,
          advert: false,
          users: false,
          products: false,
          finances: false,
          financeReport: false,
          productsEdit: false,
          productsRelease: false,
        },
        dropdown: [
          { label: this.$t('users.roles.factory_admin'), value: 'factory_admin' },
          { label: this.$t('users.roles.otk'), value: 'otk' }
        ]
      }
    },
    watch: {
      'rights.financeReport': function (val) {
        if (val) {
          this.rights.finances = true
        }
      },
      'rights.finances': function (val) {
        if (!val) {
          this.rights.financeReport = false
        }
      },
      'user.role': function (val) {
        this.user.password = null
        this.rights = {
          analytic: false,
          advertising: false,
          users: false,
          production: false,
          finance: false,
          acts: false
        }
      },
      'rights.productsEdit': function(val) {
          if(val)
              this.rights.products = true;
      },
      'rights.productsRelease': function(val) {
          if(val)
              this.rights.products = true;
      },
    },
    methods: {
      dropdownAppear (open) {
        if (open) {
          document.querySelector('.el-dropdown-link').classList.add('open')
          setTimeout(()=>{
            let dropdown = document.querySelector('.el-dropdown-menu')
            let top = dropdown.getBoundingClientRect().top - 60
            let left = dropdown.getBoundingClientRect().left - 20
            dropdown.style.top = top + 'px'
            dropdown.style.left = left + 'px'
            dropdown.style.width = 290 + 'px'
          },20)
        } else {
          document.querySelector('.el-dropdown-link').classList.remove('open')
        }
      },
      changeRole (role) {
        this.user.role = role
      },
      passGen () {
        let settings
        if (this.user.role.value === 'factory_admin' ) {
          settings = {
            sets: {
              letters: true,
              lowercase: true,
              uppercase: false,
              digit: true,
              special: false,
              space: false
            },
            length: 8
          }
        } else {
          settings = {
            sets: {
              letters: false,
              lowercase: true,
              uppercase: false,
              digit: true,
              special: false,
              space: false
            },
            length: 4
          }
        }
        this.user.password = pc.create(settings)
      },
      cancel () {
        this.$router.push({name: 'UserList'})
      },
      async createUser () {
        let data
        switch(true) {
          case this.user.role.value === 'factory_admin':
            data = {
              fio: this.user.fio,
              role: this.user.role.value,
              password: this.user.password,
              email: this.user.email,
              permissions: []
            }
            Object.keys(this.rights).forEach(permission => {
              if (this.rights[permission]) {
                if (permission === 'financeReport') {
                  data.permissions.push('finance-report')
                } else if(permission === "productsEdit") {
                  data.permissions.push('products.edit')
                } else if(permission === "productsRelease") {
                  data.permissions.push('products.release')
                } else {
                  data.permissions.push(permission)
                }
              }
            })
            break
          case this.user.role.value === 'otk':
            data = {
              fio: this.user.fio,
              role: this.user.role.value,
              pin: this.user.password
            }
            break
        }
        try {
          let resp = await this.$store.dispatch('backoffRequest', {
            url: '/v1/factory/users',
            method: 'POST',
            data: data
          })
          if (resp.data.result) {
            this.$store.dispatch('showNotice', {
              title: this.$t('common.success'),
              message: this.$t('users.user_created'),
              icon: 'warning',
              time: 4000
            })
            this.$router.push({name: 'UserList'})
          } else {
            throw(resp.data.error)
          }
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
