import Vue from 'vue'
import Vuex from 'vuex'
import helper from './common/helper'
import notice from './common/notice'

import notification from './notification'
import user from './user'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    notification,
    notice,
    helper,
    user
  }
})
