import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
// views
import login from '../views/authentication/login'

import productionList from '../views/production/index'
import productionRead from '../views/production/read'

import Analytics from '../views/analytics/index'

import advert from '../views/advert/index'

import userList from '../views/users/index'
import userCreate from '../views/users/create'
import userRead from '../views/users/read'

import finance from '../views/finance/index'

import codeSearch from '../views/code_search/index'

import settings from '../views/settings/index'
import help from '../views/help/index.vue'

Vue.use(VueRouter)

const isAuth = (to, from, next) => {
  if (store.getters.isAuth) {
    switch (true) {
      case to.name === 'ProductionList' && store.getters.getPermission('products'):
        next()
        return
      case to.name === 'ProductionRead' && store.getters.getPermission('products'):
        next()
        return
      case to.name === 'Analytics' && store.getters.getPermission('analytics'):
        next()
        return
      case to.name === 'Finance' && store.getters.getPermission('finances'):
        next()
        return
      case to.name === 'Advert' && store.getters.getPermission('advert'):
        next()
        return
      case to.name === 'UserList' && store.getters.getPermission('users'):
        next()
        return
      case to.name === 'UserCreate' && store.getters.getPermission('users'):
        next()
        return
      case to.name === 'UserRead' && store.getters.getPermission('users'):
        next()
        return
      case to.name === 'Settings':
      case to.name === 'Help':
        next()
        return
      case to.name === 'CodeSearch' && store.getters.getPermission('products'):
        next()
        return
      default:
        switch(true) {
          case to.name === 'ProductionList' || to.name === 'ProductionRead':
            next('/analytics')
            return
          case to.name === 'Analytics':
            next('/finance')
            return
          case to.name === 'Finance':
            next('/commercial')
            return
          case to.name === 'Advert':
            next('/UserList')
            return
          case to.name === 'UserList' || to.name === 'UserCreate' || to.name === 'UserRead' :
            next('/settings')
            return
          default:
            next('/settings')
            return
        }
    }
  } else {
    next('/')
  }
}
const isNotAuth = (to, from, next) => {
  if (!store.getters.isAuth) {
    next()
    return
  }
  next('/production')
}

const routes = [
  { path: '/', name: 'Login', component: login, beforeEnter: isNotAuth },
  { path: '/production', name: 'ProductionList', component: productionList, beforeEnter: isAuth },
  { path: '/production/:id', name: 'ProductionRead', component: productionRead, beforeEnter: isAuth },
  { path: '/analytics', name: 'Analytics', component: Analytics, beforeEnter: isAuth },
  { path: '/UserList', name: 'UserList', component: userList,
    children: [
      { path: 'create', name: 'UserCreate', component: userCreate, beforeEnter: isAuth},
      { path: ':id', name: 'UserRead', component: userRead, beforeEnter: isAuth }
    ], beforeEnter: isAuth },
  { path: '/finance', name: 'Finance', component: finance, beforeEnter: isAuth },
  { path: '/commercial', name: 'Advert', component: advert, beforeEnter: isAuth },
  { path: '/settings', name: 'Settings', component: settings, beforeEnter: isAuth },
  { path: '/code_search', name: 'CodeSearch', component: codeSearch, beforeEnter: isAuth },
  { path: '/help', name: 'Help', component: help, beforeEnter: isAuth },
  { path: '*', redirect: '/' }
]

const router = new VueRouter({
  routes
})

export default router
