<template>
  <div>
    <chart :chart-data="dataCollection" :options="chartOptions" :height="220"></chart>
  </div>
</template>

<script>
import chart from '../chart'
import i18n from '../../i18n'
export default {
  name: "outerCodes",
  props: ['time', 'amount'],
  data () {
    return {
      dataCollection: {
        labels: [],
        datasets: [{
          data: []
        }]
      },
      chartOptions: {}
    }
  },
  watch: {
    time: function () {
      this.fillGraphData()
    }
  },
  methods: {
    fillGraphData() {
      let dataCollection = {
        labels: [],
        datasets: [
          {
            fill: false,
            borderColor: ['rgb(210, 25, 25)'],
            borderWidth: 2,
            pointRadius: '0',
            pointHoverRadius: '6',
            pointHoverBorderWidth: 2,
            pointHoverBorderColor: 'rgb(210, 25, 25)',
            pointHoverBackgroundColor: 'rgb(255, 255, 255)',
            data: []
          }
        ]
      }
      this.time.forEach(time => {
        dataCollection.labels.push(time)
      })
      this.amount.forEach(amount => {
        dataCollection.datasets[0].data.push(amount)
      })
      this.chartOptions = {
        legend: {
          display: false
        },
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            id: 'left-y-axis',
            type: 'linear',
            position: 'left',
            gridLines: {
              display: true,
              drawBorder: false,
              drawOnChartArea: true,
              lineWidth: 1,
              borderDash: [8, 4]
            },
            ticks: {
              maxTicksLimit: 5,
              autoSkip: true,
              padding: 5,
              callback: function (value, index, array) {
                return Number(value).toLocaleString()
              }
            }
          }],
          xAxes: [{
            gridLines: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false,
              lineWidth: 2,
              color: 'rgb(214, 55, 71)'
            },
            ticks: {
              maxTicksLimit: 10,
              maxRotation: 0,
              autoSkip: true,
              callback: (value, index, array) => {
                return new Date(value).getDate() + ' ' + this.getMonth(value)
              }
            }
          }]
        },
        tooltips: {
          displayColors: false,
          mode: 'index',
          intersect: false,
          borderColor: 'rgba(177, 184, 199, 1)',
          caretPadding: 10,
          caretSize: 0,
          cornerRadius: 2,
          borderWidth: 1,
          backgroundColor: 'rgba(37, 39, 51, 0.8)',
          titleFontColor: 'rgba(255, 255, 255, 1)',
          titleFontStyle: 'normal',
          bodyFontColor: 'rgba(255, 255, 255, 1)',
          footerFontColor: 'rgba(227, 231, 237, 0.7)',
          footerFontSize: 12,
          bodySpacing: 5,
          callbacks: {
            title: () => {
              return i18n.t('chart.codes')
            },
            beforeLabel: () => {
              return i18n.t('chart.used') + ":"
            },
            label: (item) => {
              return item.value + i18n.t('chart.pieces')
            },
            footer: (value) => {
              return new Date(value[0].label).toLocaleDateString()
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: false
        }
      }
      this.dataCollection = dataCollection
    },
    getMonth (date) {
      let month = new Date(date).getMonth()
      return i18n.t('chart.month['+month+']')
    },
  },
  mounted() {
    this.fillGraphData()
  },
  components: {
    chart
  }
}
</script>

