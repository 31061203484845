<template>
  <div class="workflow">
    <div class="header">
      <breadcrumbs></breadcrumbs>
      <div class="info-panel">
        <div class="help-button">
            <router-link
                :to="{name: 'Help'}"
                tag="a"
            >
                <img alt="" :title="$t('layout.help')"
                    src="../../assets/images/layout/workflow/help-button.svg"
                    width="24px" height="24px"
                />
            </router-link>
        </div>

        <div class="ubuntu-regular">
			<a class="href" href="tel:88005552940">{{$t('layout.phone')}}: 8 (800) 555-29-40</a>
          <br>
          <a class="href" href="mailto:info@integrity.ooo">email: info@integrity.ooo</a>
        </div>

        <div class="divider"/>

        <notification></notification>

        <div class="divider"/>

        <template v-if="$store.getters.getPermission('finances')">
			<span class="balance">{{$t('layout.balance')}}:</span>
          <span class="amount">{{ $store.getters.getBalance }}</span>
          <div class="divider"></div>
        </template>

        <span class="factory">{{ $store.getters.getFactoryName }}</span>
      </div>
    </div>
    <div class="content-wrapper">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import notification from '../../components/header/notification'
import breadcrumbs from '../../components/header/breadcrumbs'

export default {
  name: "workflow",
  watch: {
    "$route": function() {
      this.getBalance()
    },
  },
  methods: {
    async getBalance() {
      if (this.$store.getters.getPermission('finances')) {
        try {
          let resp = await this.$store.dispatch('backoffRequest', {
            method: 'GET',
            url: '/v1/factory/finances/balance'
          })
          this.$store.commit('setBalance', resp.data.balance)
        } catch (e) {
          console.log(e)
        }
      }
    }
  },
  async created () {
    this.getBalance()
  },
  components: {
    breadcrumbs,
    notification
  }
}
</script>
