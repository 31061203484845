<template>
  <div class="auth">
    <sidebar></sidebar>
    <workflow></workflow>
  </div>
</template>

<script>
import sidebar from './sidebar'
import workflow from './workflow'
export default {
  name: "authLayout",
  components: {
    sidebar,
    workflow
  }
}
</script>
