<template>
  <div class="help">
    <div v-if="$store.getters.getPreloaderStatus" key="preloader" id="loadingProgressG">
      <div class="loadingProgressG"></div>
    </div>
    <div v-else key="content">
        <a :href="lkURL">
			{{$t('help.personal_account_manual')}}
        </a>
        <br/>
        <a :href="otkURL">
			{{$t('help.qa_app_manual')}}
		</a>

        <br/>
        <hr style="margin: 16px"/>
        <a :href="otk_download_url">
			{{$t('help.download_qa_app')}}
        </a>
        <div id="otk-download-qr" v-html="otk_download_qr_svgnode"/>
    </div>
  </div>
</template>

<script>
const QRCode = require("../../util/qrcode");
import i18n from "../../i18n";

export default {
    name: "index",
    data () {
        return {
            lk_url: "",
            otk_url: "",
            otk_download_url: "",
            otk_download_qr_svgnode: "",
        }
    },
	computed: {
		lkURL: function() {
			return this.BASE_URL+`help/lk_manual_${i18n.locale}.pdf`;
		},
		otkURL: function() {
			return this.BASE_URL+`help/otk_manual_${i18n.locale}.pdf`;
		},
	},

    created () {
        this.BASE_URL = "https://cdn.integrity.ooo/";
        //this.lk_url = BASE_URL+"help/lk_manual.pdf";
        //this.otk_url = BASE_URL+"help/otk_manual.pdf";
        this.otk_download_url = this.BASE_URL+"releases/integrity-otk.apk";

        this.$store.commit('setPreloader', false);
    },

    mounted() {
        const svgNode = QRCode({
            msg :  this.otk_download_url
            ,dim :   180
            ,pad :   5
            ,mtx :   7
            ,ecl :  "H"
            ,ecb :   0
            ,pal : ["#000000", "#f8f9fb"]
            ,vrb :   1

        });
        this.otk_download_qr_svgnode = svgNode.outerHTML;
    }
}
</script>
