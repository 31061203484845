import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from "./i18n";
// app styles
import 'element-ui/lib/theme-chalk/index.css'
import './assets/styles/main.scss'
import './assets/js/map/jquery-jvectormap-2.0.5.css'
// components
import Axios from 'axios';

Axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
Vue.config.productionTip = false;

// global interceptors
Axios.interceptors.response.use(
	function (response) {
		return response
	},
	function (error) {
		console.log(error)
		switch (true) {
		case error.response && error.response.status === 401:
			store.commit('setPreloader', false)
			store.commit('logout')
			router.push('/')
			break
		case error.response &&
				(error.response.status === 422 || error.response.status === 403):
			let message = ''
			if (error.response.data.name === 'MoleculerClientError') {
				message = error.response.data.message
			} else if (error.response.data.data) {
				message = error.response.data.data[0].message
			} else {
				message = error.response.data.message
			}
			store.dispatch('showNotice', {
				title: i18n.t('common.attention'),
				message: message,
				icon: 'warning',
				time: 4000
			})
			break
		}
		return Promise.reject(error)
	}
);

import {
	Table,
	TableColumn,
	Pagination,
	Dropdown,
	DropdownMenu,
	DropdownItem,
	Row,
	Col,
	Tabs,
	TabPane,
	Select,
	Option,
	DatePicker,
	Input,
	Popover,
	Button,
	Autocomplete,
} from 'element-ui'

Vue.component('el-table', Table)
Vue.component('el-table-column', TableColumn)
Vue.component('el-pagination', Pagination)
Vue.component('el-dropdown', Dropdown)
Vue.component('el-dropdown-menu', DropdownMenu)
Vue.component('el-dropdown-item', DropdownItem)
Vue.component('el-row', Row)
Vue.component('el-col', Col)
Vue.component('el-tabs', Tabs)
Vue.component('el-tab-pane', TabPane)
Vue.component('el-select', Select)
Vue.component('el-option', Option)
Vue.component('el-date-picker', DatePicker)
Vue.component('el-input', Input)
Vue.component('el-popover', Popover)
Vue.component('el-button', Button)
Vue.component('el-autocomplete', Autocomplete)

if (localStorage.getItem('integrity.cabinet.token')) {
  store.dispatch('backoffRequest', {
        url: '/v1/verify/token',
        method: 'POST',
        data: {
          token: localStorage.getItem('integrity.cabinet.token')
        }
      })
  .then( resp => {
    store.commit('setToken', localStorage.getItem('integrity.cabinet.token'))
    store.commit('setFactoryName', resp.data.user['factory_name'])
	store.commit('setFactoryId', resp.data.user['factory_id'])
    store.commit('setPermissions', resp.data.user.permissions)
    store.commit('setUserName', resp.data.user.fio)
    // set notification when reload page
    if (resp.data.notifications.rows.length) {
      let notifications = resp.data.notifications.rows
      let formatedArray = []
      notifications.forEach(value => {
        formatedArray.push({
          title: '',
          action: value.message,
          time: value.created
        })
      })
      store.commit('setNotifications', formatedArray)
    }
    init()
  })
  .catch(error => {
    store.commit('logout')
    init()
  })
} else {
	init()
}

function init () {
	new Vue({
		router,
		store,
		render: h => h(App),
		i18n,
	}).$mount('#app')
}
