<template>
  <div class="breadcrumbs">
    <transition mode="out-in" name="fade">
      <div class="breadcrumbs-row">
		  <a href="#" v-if="haveBackHistory">
              <img v-if="path.length > 1" @click="$router.go(-1)"
                   alt="" class="back-button"
                   src="../../assets/images/components/pagination/arrow_left.svg"
              />
          </a>

          <router-link :to="'/' + path[0]" tag="span" :key="path[0]" class="title pointer">
            {{ transformRoute(path[0]) }}
          </router-link>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'breadcrumbs',
  data () {
    return {
      path: [],
      prevRoute: undefined,
    }
  },
  watch: {
    '$route': function (val) {
      this.path = this.$route.fullPath.split('/')
      this.path.shift()
    }
  },
  computed: {
    haveBackHistory() {
      return (window.history) ? !!window.history.state : false;
    },
  },
  methods: {
    transformRoute (rt) {
      const route = rt.split("?")[0];
      switch (true) {
        case route === 'production':
			return this.$t('layout.sidebar.products')
        case route === 'analytics':
			return this.$t('layout.sidebar.analytics')
        case route === 'settings':
			return this.$t('layout.sidebar.settings')
        case route === 'users':
			return this.$t('layout.sidebar.users')
        case route === 'UserList':
			return this.$t('layout.sidebar.user_list')
        case route === 'commercial':
			return this.$t('layout.sidebar.advert')
        case route === 'finance':
			return this.$t('layout.sidebar.finances')
        case route === 'create':
			return this.$t('layout.sidebar.create')
        case route === 'code_search':
			return this.$t('layout.sidebar.code_search')
        case route === 'help':
			return this.$t('layout.sidebar.help')
        default:
          return route
      }
    }
  },
  created () {
    this.path = this.$route.fullPath.split('/')
    this.path.shift()
  },
}
</script>

