// messages = [{title: String, action: String, time: time }]

import i18n from "../i18n";

const state = {
  open: false,
  messages: [{title: '', action: i18n.t('layout.no_new_notifications'), time: '', dummy: true}]
}
const getters = {
  isNotificationOpen: state => state.open,
  getNotificationMessages: state => state.messages
}
const mutations = {
  openNotification (state) {
    state.open = true
  },
  closeNotification (state) {
    state.open = false
  },
  setNotifications (state, data) {
    if (state.messages[0].action === i18n.t('layout.no_new_notifications')) {
      state.messages = []
    }
    data.forEach(value => {
      state.messages.push(value)
    })
  }
}
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
