<template>
  <div class="d-flex">
    <doughnut class="doughnut-graph" :chart-data="dataCollection" :options="chartOptions"></doughnut>
    <div class="legend-wrapper">
      <div class="legend-item" v-for="(item, index) in legend" :key="item.label">
        <span :style="{color: getLegendColor(index)}">
          {{ item.percent }} %
        </span>
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script>
import doughnut from '../doughnut'
export default {
  name: "doughnutChart",
  props: ['chartData'],
  data () {
    return {
      dataCollection: {
        labels: [],
        datasets: [{
          borderWidth: 0,
          borderAlign: 'inner',
          data: [5,4,3,2,1],
          backgroundColor: [
            'rgba(210, 25, 25, 1)',
            'rgba(210, 25, 25, .75)',
            'rgba(210, 25, 25, .5)',
            'rgba(210, 25, 25, .25)',
            'rgba(227, 231, 237, 0.5)',
          ]
        }]
      },
      chartOptions: {}
    }
  },
  computed: {
    legend: function () {
      let title = this.chartData.slice()
      title.sort(function(a, b) {
        return b.count - a.count
      })
      let arr = []
      title.forEach(item => {
        let summ = 0
        let dash = {}
        dash.label = item.region
        this.chartData.forEach(value => {
          summ += parseInt(value.count)
        })
        dash.percent = (item.count / summ * 100).toFixed(2).replace(/0*$/, '').replace(/\.$/, '')
        arr.push(dash)
      })
      return arr
    }
  },
  watch: {
    chartData: function () {
      this.fillGraphData()
    }
  },
  methods: {
    fillGraphData() {
      let arr = this.chartData.slice()
      arr.sort(function(a, b){
        return b.count - a.count
      })
      let dataCollection = {
        labels: [],
        datasets: [
          {
            borderWidth: 0,
            borderAlign: 'inner',
            data: [],
            backgroundColor: [
              'rgba(210, 25, 25, 1)',
              'rgba(210, 25, 25, .75)',
              'rgba(210, 25, 25, .5)',
              'rgba(210, 25, 25, .25)',
              'rgba(227, 231, 237, 0.5)',
            ]
          }
        ]
      }
      arr.forEach(item => {
        dataCollection.labels.push(item.region)
        dataCollection.datasets[0].data.push(item.count)
      })
      this.chartOptions = {
        cutoutPercentage: 70,
        legend: {
          display: false
        },
        maintainAspectRatio: false,
        tooltips: {
          enabled: false
        }
      }
      this.dataCollection = dataCollection
    },
    getLegendColor(index) {
      let color = [
        'rgba(210, 25, 25, 1)',
        'rgba(210, 25, 25, .75)',
        'rgba(210, 25, 25, .5)',
        'rgba(210, 25, 25, .25)',
        'rgba(177, 184, 199, 1)',
      ]
      return color[index]
    }
  },
  mounted() {
    this.fillGraphData()
  },
  components: {
    doughnut
  }
}
</script>

<style scoped lang="scss">
.doughnut-graph {
  width: 220px;
  height: 220px;
}
.legend-wrapper {
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  .legend-item {
    margin: 10px 0;
    font-family: Ubuntu-regular, serif;
    font-size: 14px;
    span {
      font-family: Ubuntu-medium, serif;
    }
  }
}
</style>

