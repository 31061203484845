<template>
  <div class="content">
    <transition mode="out-in" name="fade">
      <div v-if="$store.getters.getPreloaderStatus" key="preloader" id="loadingProgressG">
        <div class="loadingProgressG"></div>
      </div>
      <div v-else key="content" class="production-read">
        <div class="cable-wrapper">
          <div class="image">
			<transition mode="out-in">
				<img id="certPreview"
					src="../../assets/images/production/cert_placeholder.svg"
					alt=""
					v-if="!certThumbnailDataURL" />
				<img v-else class="cert-thumbnail" alt="" :src="certThumbnailDataURL" />
			</transition>

			<div class="cert-placeholder-text">
				<div class="cert-thumbnail-loading-indicator"
						v-if="isCertThumbnailLoading">
					<div class="file-downloading-busy-rotating-circle"/>
				</div>

				<span v-else-if="certThumbnailErrorDesc">{{certThumbnailErrorDesc}}</span>
			</div>

            <div class="download">
              <template v-if="cable.status === 'released'">
				  <button @click="openPDF" class="btn transparent floating">{{$t('common.download_pdf')}}</button>
              </template>
              <template v-else>
			  	<template v-if="cable.passport_attached === true">
					<button @click="openPDF" class="btn transparent floating">{{$t('common.download_pdf')}}</button>
				</template>
                <button v-if="!cable.parent" @click="updateCertRequest" class="btn transparent floating mt-20">
					{{cable.passport_attached ? $t('production.replace_pdf') :
					$t('production.upload_pdf')}}
				</button>
                <input type="file" class="d-none" @change="changeCert" id="newCert" accept="application/pdf">
              </template>
            </div>
          </div>
          <div class="description ml-20 ubuntu-regular">
            <div class="text-gray d-flex align-center font-14 pointer" @click="copy">
              <span>{{this.$route.params.id}}</span>
              <img class="ml-10" src="../../assets/images/production/link.svg" alt="" />
            </div>

			<div v-if="cable.parent" class="text-gray d-flex align-center font-14 pointer">
				<span>{{$t('production.part_of_product')}}</span>
				<router-link :to="{name: 'ProductionRead', params: {id: cable.parent}}"
					tag="a" class="cable-name" target="_blank">
					{{cable.parent}}
				</router-link>
			</div>

            <div><br/></div>

			<template v-if="!cable.parent && cable.passport_attached===false">
				<div class="btn transparent secondary floating default">
					{{$t('production.attach_cert')}}
				</div>
				<div><br/></div>
			</template>
            <div class="d-flex align-center">
            <div class="font-18">
              {{ new Date(cable.created).toLocaleDateString().split('.').reverse().join('-')}} |
              {{ new Date(cable.created).toLocaleTimeString().split(':').slice(0,2).join(':') }}
            </div>

            <div class="d-flex align-center" style="padding-left:10px">
              <span v-if="cable.status !== 'released'" class="font-18">{{$t('production.status.new')}}</span>
              <transition mode="out-in" name="fade">
			    <template v-if="cable.parent || cable.passport_attached===true">
					<button v-if="!cable.parent && cable.status === 'new'" @click="releaseCable" class="btn transparent red floating ml-10">
						{{$t('production.release')}}
					</button>
					<div v-if="cable.status === 'released'" class="btn transparent secondary floating default">
						{{$t('production.released')}}
					</div>
				</template>
              </transition>
            </div>
            </div>

            <div><br/></div>
            <div class="d-flex align-center">
              <span class="nowrap ubuntu-medium">{{$t('production.fields.drum_number')}}</span>
              <div class="input-wrapper ml-10">
                <input :readonly="cable.status ==='released'"
                       @blur="updateDataField($event)"
                       @input="updateDataField($event)"
                       class="input" v-model="newDrumNumber" type="text"/>
              </div>
            </div>
            <div><br/></div>

            <div class="d-flex align-center">
              <span class="nowrap ubuntu-medium">{{$t('production.fields.length')}}</span>
              <div class="input-wrapper ml-10">
                <input :readonly="cable.status ==='released'"
                       @blur="updateDataField($event)"
                       @input="updateDataField($event)"
                       class="input" v-model="newLength" type="text"/>
				{{$t('common.meters_letter')}}
              </div>
            </div>
            <div><br/></div>

			<div v-if="cable.child_products && cable.child_products.length > 0">
				<span>{{$t('production.marked_with_multiple_marking_types')}}:</span>
				<div v-for="child in cable.child_products" v-bind:key="child.uuid">
					<div class="text-gray d-flex font-14 child-cable-row">
						<img v-if="cable.errors && cable.errors.error_source ===
							child.uuid"
							src="../../assets/images/components/input/warning.svg"
							class="cable-warning-img"
							:title="$t('production.has_release_errors')"
						/>
						<router-link :to="{name: 'ProductionRead', params: {id: child.uuid}}"
							tag="a" class="cable-name" target="_blank">
							{{child.uuid}}
						</router-link>
						<span class="child-cable-desc">{{child.type}} ({{child.codes_qty}} {{$t('production.codes')}})</span>
					</div>
				</div>
				<br/>
			</div>

            <div class="d-flex align-center">
				<span class="nowrap ubuntu-medium">{{$t('production.product_consumer')}}</span>
                <div class="input-wrapper ml-10">
                    <el-autocomplete
                        v-model="newConsumer"
                        :placeholder="$t('production.enter_consumer_name_tooltip')"
                        clearable
                        :fetch-suggestions="SelectConsumer_fetch"
                        @select="SelectConsumer_onSelect"
                        @clear="SelectConsumer_onClear"
                        @blur="SelectConsumer_onBlur"
                    >
                    </el-autocomplete>
                </div>
            </div>
            <div><br/></div>

            <div class="d-flex align-center">
				<span class="nowrap ubuntu-medium">{{$t('production.description')}}</span>
                <br/>
            </div>
            <div class="d-flex align-center">
                <div class="input-wrapper ml-10">
                    <el-input @blur="updateDataField($event)"
                        @input="updateDataField($event)"
                        v-model="newDescription" type="textarea"
                        :rows="3"/>
                </div>
            </div>

            <div class="d-flex align-center">
				<span class="nowrap ubuntu-medium">{{$t('production.comment')}}</span>
                <br/>
            </div>
            <div class="d-flex align-center">
                <div class="input-wrapper ml-10">
                    <el-input @blur="updateDataField($event)"
                        @input="updateDataField($event)"
                        v-model="newComment" type="textarea"
                        :rows="3"/>
                </div>
            </div>
          <div>

          <br/>
          <div class="cable-errors" v-if="has_errors">
			  <span class="red">{{$t('production.release_error')}}: {{errors.error_description}}</span><br/>
              <div v-for="p in error_products" v-bind:key="p.uuid">
                  <span>
                      <router-link :to="{name: 'ProductionRead', params:
                          {id: p.uuid}}" tag="a" class="cable-name"
                          target="_blank">
                          {{p.uuid}}
                      </router-link>
					  ({{p.num_collisions}} {{$t('production.codes')}})
                  </span><br/>
              </div>
          </div>

          <transition name="fade" mode="out-in">
              <div v-if="!cable.parent" class="save-block" :class="[data_modified ? 'open' : '']">
                <button
                    class="btn red floating"
                    @click="SaveButton_onClick"
                >{{$t('production.save_changes')}}</button>
              </div>
          </transition>

          </div>
          </div>

			<div style="flex-grow:1"/>
			<div class="floating" style="display:flex; flex-direction:column; float:right">
			<div style="display:flex; flex-direction:row">
          <div @click="showNewMaterial"
               v-if="!cable.parent && cable.status !== 'released'"
               :class="newProperty ? 'secondary transparent' : 'red'"
			   class="btn floating add-property" style="float:left">{{$t('production.add_attachment')}}</div>
          <button @click="deleteCable"
               v-if="cable.status !== 'released'"
			   class="btn transparent red floating delete"
			   style="float:left">{{$t('production.delete_product')}}</button>
               </div>

			<div><br/></div>
			</div>
        </div>

        <div class="materials">
          <transition name="fade" mode="out-in">
            <cable-property-edit :class="[newProperty ? 'open' : '']" @close="closeNewMaterial"></cable-property-edit>
          </transition>
          <div class="property-wrapper">
            <cable-property @remove="getCableInfo" v-for="(file, index) in cable.files" :file="file" :status="cable.status" :key="index + ' file'"></cable-property>
          </div>
        </div>

        <div v-if="cable.history && cable.history.length > 0">
			<div class="cable-history-header">{{$t('production.changes_history')}}</div>
			<div class="cable-history">
            <div v-for="(historyItem, index) in cable.history.slice(0, showHistoryRows)"
                    v-bind:key="index"
                    class="cable-history-row">
                <span class="cable-history-row-number">{{cable.history.length-index}}</span>
                <span class="cable-history-cell cable-history-row-timestamp">
                    {{formatDateFromTimestamp(historyItem.timestamp)}}
                </span>
                <span class="cable-history-cell cable-history-row-user">
                    {{historyItem.user_display_name}}
                </span>
                <span class="cable-history-cell cable-history-row-name">
                    {{historyItemToDescription(historyItem)}}
                </span>
            </div>
            </div>

            <div v-if="cable.history.length > showHistoryRows"
                    class="cable-history-show-all text-red"
                    @click="showHistoryRows = cable.history.length">
				{{$t('production.show_all')}}
            </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import cableProperty from '../../components/production/cableProperty'
import cablePropertyEdit from '../../components/production/cablePropertyEdit'

import pdfjs from "@bundled-es-modules/pdfjs-dist";
pdfjs.GlobalWorkerOptions.workerSrc = "/pdfjs/pdf.worker.min.js";

export default {
  name: "read",
  data () {
    return {
      cable: {},
      newLength: '',
      newProperty: false,
      newDrumNumber: '',
      newDescription: '',
      newComment: '',
      newConsumer: "",
      newConsumer_uuid: null,
      data_modified: false,
      has_errors: false,
      errors: null,
      error_products: [],
      showHistoryRows: 5,
	  isCertThumbnailLoading: true,
	  certThumbnailErrorDesc: "",
	  certThumbnailDataURL: undefined,
    }
  },
  methods: {
    copy () {
      let textArea = document.createElement('textArea')
      textArea.value = this.$route.params.id
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      textArea.remove();
      this.$store.dispatch('showNotice', {
        title: this.$t('common.attention'),
        message: this.$t('production.id_copied'),
        icon: 'warning',
        time: 4000
      })
    },
    async openPDF () {
        // unique URL each time to bypass browser's caching
        let link = '';
        if(this.cable.status == "released") {
            link = this.cable.passport;
        } else {
            const resp = await this.$store.dispatch('backoffRequest', {
                method: 'POST',
                url: '/v1/auth/admin/download_token',
            });
            const token = resp.data.token;
            link =
                `${process.env.VUE_APP_BASE_URL}/v1/factory/products/attachment/${token}/${this.cable.uuid}/cert/cert.pdf`;
        }
        window.open(link + '?cache='+Date.now(), "cert");
    },
    getTimeZone (time) {
      time = new Date(time).getTimezoneOffset()
      time /= 60
      if (time % 1 != 0) {
        time = Math.trunc(time)
      }
      if (time >= 0) {
        time = '-' + time + ':00'
      } else {
        time = '+' + Math.abs(time) + ':00'
      }
      return time
    },
    showNewMaterial() {
      this.newProperty = true
    },
    closeNewMaterial() {
      this.newProperty = false
      this.getCableInfo()
    },
    // change cable length
    async updateLength(event) {
      if(this.cable.length === this.newLength) return
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          url: `v1/factory/products/cable/length/${this.$route.params.id}`,
          method: 'PUT',
          data: {
            length: parseInt(this.newLength)
          }
        })
        if(!resp.data.result) {
          throw(resp)
        } else {
          event.target.blur()
          this.$store.dispatch('showNotice', {
            title: this.$t('common.attention'),
            message: this.$t('production.data_changed'),
            icon: 'warning',
            time: 4000
          })
          this.getCableInfo()
        }
      } catch(e) {
        this.newLength = this.cable.length
        if (e.data && !e.data.result) {
          this.$store.dispatch('showNotice', {
            title: this.$t('common.attention'),
            message: e.data.error,
            icon: 'warning',
            time: 4000
          })
        }
      }
    },
    async updateDrumNumber(event) {
      if(this.cable.drum_number === this.newDrumNumber) return
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          url: `v1/factory/products/cable/drum_number/${this.$route.params.id}`,
          method: 'PUT',
          data: {
            drum_number: this.newDrumNumber
          }
        })
        if(!resp.data.result) {
          throw(resp)
        } else {
          event.target.blur()
          this.$store.dispatch('showNotice', {
            title: this.$t('common.attention'),
            message: this.$t('production.data_changed'),
            icon: 'warning',
            time: 4000
          })
          this.getCableInfo()
        }
      } catch(e) {
        this.newDrumNumber = this.cable.drum_number
        if (e.data && !e.data.result) {
          this.$store.dispatch('showNotice', {
            title: this.$t('common.attention'),
            message: e.data.error,
            icon: 'warning',
            time: 4000
          })
        }
      }
    },
    async updateDescription(event) {
    },
    async SelectConsumer_fetch(qs, cb) {
        // drop uuid, have to be selected from list
        this.newConsumer_uuid = null;

        // don't show suggestions if search string is too short
        if(!qs || qs.length < 2) {cb([]); return;}

        const f = (qs != undefined) ? qs : "";
        const resp = await this.$store.dispatch('backoffRequest', {
            url: `v1/factory/consumers`,
            method: "GET",
            params: {
              filter: f,
            }
        });
        cb(resp.data.orgs.map((i) => { return { value: i.name, uuid: i.uuid }}));
    },
    SelectConsumer_onSelect(item) {
        this.newConsumer_uuid = item.uuid;
        this.updateDataField();
    },
    SelectConsumer_onClear() {
        this.newConsumer = "";
        this.newConsumer_uuid = null;
        this.updateDataField();
    },
    SelectConsumer_onBlur() {
      // reset text if uuid is not defined via onSelect
      if(this.newConsumer_uuid == null) {
        this.newConsumer = "";
      }
      this.updateDataField();
    },
    // change cable cert
    updateCertRequest() {
	  let c = true;
	  if(this.cable.passport_attached === true) {
	    c = confirm(this.$t('production.replace_cert_dialog'));
      }
	  if(c) {
        document.querySelector('#newCert').click()
      }
    },
	async deleteCable() {
		if(confirm(this.$t('production.delete_product_dialog'))) {
			try {
				let rest = await this.$store.dispatch('backoffRequest', {
					url: `/v1/factory/products/${this.$route.params.id}`,
					method: 'DELETE',
				});
				this.$router.push('/production');
			} catch(e) {
				console.log(e);
				let msg = "";
				if(e.data) { msg = e.data.error; }
				this.$store.dispatch('showNotice', {
					title: this.$t('common.attention'),
					message: this.$t('production.cant_delete_product') + ': ' + msg,
					icon: 'warning',
					time: 10000
				});
			}
		}
	},
	async changeCert (e) {
		let files = e.target.files || e.dataTransfer.files
		if(!files.length) return;

		let pdf_preview = null;
		try {
			// pdf thumbnail generator

			const makeThumbnail = (page, size) => {
				const w = size;
				const viewport = page.getViewport({scale: 1});
				const scale = w / viewport.width;
				const scaled_viewport = page.getViewport({scale});
				const canvas = document.createElement("canvas");
				canvas.width = scaled_viewport.width;
				canvas.height = scaled_viewport.height;
				return page.render({
					canvasContext: canvas.getContext("2d"),
					viewport: scaled_viewport,
				}).promise.then(() => {
					return canvas.toDataURL("image/jpeg");
				});
			};

			const file_contents = await new Promise((resolve, reject) => {
				const fileReader = new FileReader();
				fileReader.onload = function() {
					const typedarray = new Uint8Array(this.result);
					resolve(typedarray);
				};
				fileReader.onerror = (e) => {
					reject(e);
				};
				fileReader.readAsArrayBuffer(files[0]);
			});

			const pdf = await (pdfjs.getDocument(file_contents).promise);
			const page = await pdf.getPage(1);
			const thumb = await makeThumbnail(page, 300);

			pdf_preview = thumb;
		} catch(e) {
			// pass
		}

		let form = new FormData()
		form.set('cable_id', this.$route.params.id)
		if(pdf_preview) {
			form.set("thumbnail", pdf_preview);
		}

		form.set('file', files[0])

		try {
			let resp = await this.$store.dispatch('backoffRequest', {
				url: '/v1/factory/products/certificate',
				method: 'PUT',
				data: form,
				headers: {'Content-Type': 'multipart/form-data' }
			})
			if (resp.data.status !== 'ok') {
				throw(resp)
			}
			this.$store.dispatch('showNotice', {
				title: this.$t('common.attention'),
				icon: 'warning',
				message: this.$t('production.cert_uploaded'),
				time: 4000
			})

			await this.getCableInfo();
			await this.updateCertThumbnail();
		} catch(e) {
			console.log(e)
		}
	},
    // get cable info
    async getCableInfo() {
      try {
        // rotate modified fields array to object
        const modified_array = this.updateDataField();
        const modified = modified_array.reduce((r, i) => { r[i]=true; return r;}, {});

        let resp = await this.$store.dispatch('backoffRequest', {
          url: `/v1/factory/products/${this.$route.params.id}`,
          method: 'GET'
        })
		if(resp.data.result === false) {
			throw new Error(resp.data.error);
		}

        const first_load = (Object.keys(this.cable).length == 0);
        this.cable = resp.data

        // don't update new* fields if not saved
        if(first_load || !modified["length"]) { this.newLength = resp.data.length; }
        if(first_load || !modified["drum_number"]) { this.newDrumNumber = resp.data.drum_number; }
        if(first_load || !modified["description"]) { this.newDescription = resp.data.description; }
        if(first_load || !modified["comment"]) { this.newComment = resp.data.comment; }
        if(first_load || !modified["consumer_org"]) {
            if(resp.data.consumer_org) {
                this.newConsumer = resp.data.consumer_org.name;
                this.newConsumer_uuid = resp.data.consumer_org.org_id;
            } else {
                this.newConsumer = "";
                this.newConsumer_uuid = null;
            }
        }

        this.errors = resp.data.errors;
        this.has_errors = !!(this.errors && this.errors.error_code);
        this.error_products = [];
        if(this.has_errors) {
            for(const c in this.errors.collisions) {
                this.error_products.push({uuid: c, num_collisions:
                                         this.errors.collisions[c]});
            }
        }

        if(!this.cable.history) {
            this.cable.history = [];
        }

        this.updateDataField();

        this.$store.commit('setPreloader', false)
      } catch(e) {
        console.log(e)
		throw e;
      }
    },
    // release cable
    async releaseCable() {
        if(this.updateDataField().length > 0) {
            confirm(this.$t('production.upsaved_data_release_dialog'));
            return;
        }

		if (confirm(this.$t('production.release_dialog'))) {
        try {
          let resp = await this.$store.dispatch('backoffRequest', {
            url: `/v1/factory/products/release/${this.$route.params.id}`,
            method: 'POST'
          })
          if (resp.data.result) {
            this.$store.dispatch('showNotice', {
              title: this.$t('common.attention'),
              icon: 'warning',
              message: this.$t('production.product_checking'),
              time: 4000
            })
            this.getCableInfo()
			this.$router.push("/production");
          } else {
            this.$store.dispatch('showNotice', {
              title: this.$t('common.attention'),
              icon: 'warning',
              message: this.$t('production.product_not_released') + ": " + resp.data.error,
              time: 10000
            })
            throw(resp)
          }
        } catch (e) {
          console.log(e)
        }
      }
    },

    // check modified fields, set save button visibility
    updateDataField(event) {
        let modified_list = [];
        if(this.cable.description != this.newDescription) {
            modified_list.push("description");
        }
        if(this.cable.comment != this.newComment) {
            modified_list.push("comment");
        }
        if(this.cable.length != this.newLength) {
            modified_list.push("length");
        }
        if(this.cable.drum_number != this.newDrumNumber) {
            modified_list.push("drum_number");
        }
        if((this.cable.consumer_org && this.cable.consumer_org.org_id != this.newConsumer_uuid) ||
                (!this.cable.consumer_org && this.newConsumer_uuid)) {
            modified_list.push("consumer_org");
        }

        if(modified_list.length > 0) {
            this.data_modified = true;
        } else {
            this.data_modified = false;
        }

        return modified_list;
    },

    async SaveButton_onClick() {
        const modified = this.updateDataField();
        let errors = [];
        for(let i = 0; i < modified.length; ++i) {
            const m = modified[i];
            let errdesc = "";
            let url = null;
            let data = null;

            if(m == "length") {
                url = `v1/factory/products/cable/length/${this.$route.params.id}`;
                data = { length: parseInt(this.newLength) };
            } else if(m == "drum_number") {
                url = `v1/factory/products/cable/drum_number/${this.$route.params.id}`;
                data = { drum_number: this.newDrumNumber };
            } else if(m == "description") {
                url = `v1/factory/products/cable/description/${this.$route.params.id}`;
                data = { description: this.newDescription };
            } else if(m == "comment") {
                url = `v1/factory/products/cable/comment/${this.$route.params.id}`;
                data = { comment: this.newComment };
            } else if(m == "consumer_org") {
                url = `v1/factory/products/cable/consumer_org/${this.$route.params.id}`;
                data = { org_id: this.newConsumer_uuid };
            }

            if(!url) { continue; }

            try {
                const resp = await this.$store.dispatch("backoffRequest", {
                    url,
                    method: 'PUT',
                    data,
                });
                if(!resp.data.result) {
                    throw resp;
                }
                if(resp.data.result == false) {
                    throw resp;
                }
            } catch(e) {
                if (e.data && !e.data.result) {
                    errors.push(e.data.error);
                  this.$store.dispatch('showNotice', {
                    title: this.$t('common.attention'),
                    message: e.data.error,
                    icon: 'warning',
                    time: 4000
                  })
                }
            }

            if(errors.length == 0) {
                this.$store.dispatch('showNotice', {
                    title: this.$t('common.attention'),
                    message: this.$t('production.data_changed'),
                    icon: 'warning',
                    time: 4000
                })
            }
        }

        this.getCableInfo();
    },

    preventNav(event) {
      if(this.updateDataField().length > 0) {
        event.preventDefault();
        return event.returnValue = "";
      }
    },

    // convert history item to localised text representation
    historyItemToDescription(h) {
        try {
            const file_description = (v) => {
                let o = undefined;
                try {
                    o = JSON.parse(v);
                } catch(e) {
                    o = undefined;
                }
                if(!o) {
                    return v;
                }
                if(o.title) {
                    return o.title;
                }
                return v;
            };

            switch(h.name) {
                case "length":
				case "drum_number":
                case "description":
                case "comment":
				case "created":
				case "release":
				case "unrelease":
				case "delete":
				case "undelete":
					return this.$t('production.history.'+h.name, h);

                case "add-file":
					return this.$t('production.history.add_file', {
						desc: file_description(h.new_value)});
                case "remove-file":
					return this.$t('production.history.add_file', {
						desc: file_description(h.old_value)});
                case "replace-cert":
                    if(h.old_value) {
                        return this.$t('production.history.replace_cert');
                    } else {
						return this.$t('production.history.attach_cert');
                    }

                default:
                    return this.$t('production.history.unknown');
            }
        } catch(e) {
			return this.$t('production.history.unknown');
        }
    },

    formatDateFromTimestamp(ts) {
        const d = new Date(ts);
        return d.toLocaleDateString().split('.').reverse().join('-') + " " +
              d.toLocaleTimeString().split(':').slice(0,2).join(':');
    },

	async updateCertThumbnail() {
		if(!this.cable.passport_attached) {
			this.certThumbnailErrorDesc = this.$t('production.cert_not_attached');
			this.isCertThumbnailLoading = false;
			return;
		}

		this.isCertThumbnailLoading = true;

		try {
			const resp = await this.$store.dispatch("backoffRequest", {
				method: "GET",
				url: `v1/factory/products/cable/cert_thumbnail/${this.cable.uuid}`,
			});

			// server returned have_cert=false
			if(resp && resp.data && resp.data.result === false && resp.data.have_cert === false) {
				this.certThumbnailErrorDesc = this.$t('production.cert_not_attached');
				this.isCertThumbnailLoading = false;
				this.certThumbnailDataURL = undefined;
				return;
			}

			if(resp && resp.data && resp.data.result !== false) {
				const thumbnail_str = resp.data.thumbnail_data_url;
				this.certThumbnailDataURL = thumbnail_str;
			} else {
				throw new Error(resp.data.error);
			}
		} catch(e) {
			console.log("upload err", e);
			this.certThumbnailErrorDesc = this.$t('production.cert_upload_err_refresh');
			this.certThumbnailDataURL = undefined;
		}

		this.isCertThumbnailLoading = false;
	},
  },

  async created () {
    this.$store.commit('setPreloader', true)
    try {
      await this.getCableInfo()
      this.$store.commit('setPreloader', false)
    } catch(e) {
      this.$store.commit('setPreloader', false)
	  confirm(this.$t('production.cant_get_product_info'));
	  window.location.reload();
    }
	await this.updateCertThumbnail();
  },

  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("beforeunload", this.preventNav);
    })
  },

  mounted() {
  },

  beforeRouteLeave(to, from, next) {
    if(this.updateDataField().length > 0) {
		if(confirm(this.$t('production.unsaved_changes_dialog'))) {
			next();
		}
	} else {
		next();
    }
  },

  components: {
    cableProperty,
    cablePropertyEdit
  }
}
</script>

<style lang="scss" scoped>
  .download button.btn {
    height: 40px;
    width: 130px;
  }
  .description button.btn {
    font-size: 13px;
    height: 30px;
    font-family: Ubuntu-regular, serif;
    letter-spacing: unset;
  }
/*  .description input.input {
    height: 16px;
    width: 100px;
  }*/
  .btn.secondary.transparent {
    background: none;
    color: rgb(127, 127, 127);
    border: 2px solid rgb(127, 127, 127);
  }
  .add-property {
    margin-left: auto;
  }
  .delete {
    margin-left: 10px;
  }
  @media screen and (max-width: 810px) {
    .add-property {
      max-width: 110px;
      text-align: center;
      font-size: 12px;
    }
  }
  .save-block {
    transition: all linear .2s;
    max-height: 0px;
    display: flex;
    overflow: hidden;
    padding-top: 10px;
    &.open {
      max-height: 70px;
    }
  }
  .cable-errors {
  }
  .cable-name {
	  color: grey;
  }
  .child-cable-row {
	  display: block;
  }
  .child-cable-desc {
	  /*margin-left: 16px;*/
	  float: right;
  }

  .cable-history {
	  margin-top: 32px;
	  display: table;

    .cable-history-header {
        font-size: 16px;
		padding-bottom: 16px;
        font-family: Ubuntu-regular, serif;
    }
    .cable-history-row {
        display: table-row;
        gap: 10px;
		font-size: 14px;
    }
	.cable-history-cell {
		border-right: 10px solid transparent;
		display: table-cell;
	}
    .cable-history-row-number {
        width: 32px;
		display: block;
		color: grey;
    }
    .cable-history-row-name {
    }
	.cable-history-row-user {
		color: grey;
	}
    .cable-history-show-all {
        text-decoration: underline;
    }
  }
</style>
