<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <notice v-if="$store.getters.isNoticeOpen"></notice>
    </transition>
    <transition name="fade" mode="out-in">
      <auth-layout v-if="$store.getters.isAuth"></auth-layout>
      <unauth-layout v-else></unauth-layout>
    </transition>
  </div>
</template>

<script>
import authLayout from './views/layout/authLayout'
import unauthLayout from './views/layout/unauthLayout'
import notice from './components/notice'

export default {
  name: 'app',
  components: {
    authLayout,
    unauthLayout,
    notice
  }
}
</script>
