<template>
  <button key="content" class="btn floating red" @click="create">
	  {{$t('users.create_user')}}
  </button>
</template>

<script>
export default {
  name: "createButton",
  methods: {
    create () {
      this.$router.push({name: 'UserCreate'})
    }
  },
}
</script>
