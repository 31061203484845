<template>
  <div class="analytics">
    <transition name="fade" mode="out-in">
      <div v-if="$store.getters.getPreloaderStatus" key="preloader" id="loadingProgressG">
        <div class="loadingProgressG"></div>
      </div>
      <div v-else key="content">
        <div class="row">
          <div class="map-wrapper">
            <div class="map">
              <div class="d-flex justify-between align-center mt-10 mb-20">
                <span class="ubuntu-medium font-16">
                  Карта сканирований
                </span>
                <div class="d-flex">
                  <span class="mr-10 ubuntu-medium font-14 text-gray">Показать:</span>
                  <el-dropdown
                          id="scanMap"
                          placement="bottom-start"
                          @visible-change="mapDropdown"
                          @command="selectFilter"
                          trigger="click">
                      <span class="el-dropdown-link">
                        {{mapFilter.label}}
                      </span>
                    <el-dropdown-menu>
                      <el-dropdown-item v-for="item in filters" :key="item.value" :command="['mapChart', item]">
                        <span class="item">{{item.label}}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
<!--              <region-map :chartData="mapChart"></region-map>-->
<!--              <div id="ymap" style="width: 600px; height: 400px"></div>-->
            <div id="region-map">
              <div id="ymap" style="min-width: 300px; min-height: 500px; width: 100%; height: 100%"></div>
            </div>
            </div>
            <div class="chart">
              <div class="d-flex justify-between mt-10 mb-20">
                <span class="ubuntu-medium font-16">
                    Коды на поверхности
                </span>
                <div class="d-flex">
                  <span class="mr-10 ubuntu-medium font-14 text-gray">Показать:</span>
                  <el-dropdown
                    id="outerCodes"
                    placement="bottom-start"
                    @visible-change="outerDropdown"
                    @command="selectFilter"
                    trigger="click">
                      <span class="el-dropdown-link">
                        {{outerFilter.label}}
                      </span>
                    <el-dropdown-menu>
                      <el-dropdown-item v-for="item in filters" :key="item.value" :command="['outerCodes', item]">
                        <span class="item">{{item.label}}</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <outer-chart :amount="outerCodes.amount" :time="outerCodes.time"></outer-chart>
            </div>
          </div>
          <div class="table-wrapper">
            <div class="ubuntu-medium font-16 mt-10">Последние сканирования</div>
            <el-table :data="dataTable"
              empty-text="Нет ни одноного сканирования"
              :header-row-class-name="tableHeader">
                <el-table-column label="Дата и время" min-width="190">
                  <template slot-scope="data">
                    <span class="ubuntu-regular text-black">
                      {{data.row ? new Date(data.row.created).toLocaleDateString().split('.').reverse().join('-')  : ''}}
                    </span>
                    <span class="ubuntu-regular text-gray">
                      {{data.row ? new Date(data.row.created).toLocaleTimeString().split(':').slice(0,2).join(':') : ''}}
                      ({{ timeZone(data.row ? data.row.created : '') }})
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Город" min-width="140">
                  <template slot-scope="data">
                    <span class="ubuntu-regular text-black">
                      {{data.row ? data.row.city : ''}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Коды" min-width="70">
                  <template slot-scope="data">
                    <span class="ubuntu-medium font-14 text-black">
                      {{data.row ? data.row['code_indexes'][0] : ''}} - {{data.row ? data.row['code_indexes'][1] : ''}}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column label="Сертификат" min-width="150" align="center">
                  <template slot-scope="data">
                    <a class="btn secondary passport" :href="data.row.passport" target="_blank">Просмотр</a>
                  </template>
                </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row mt-20">
          <div class="left-chart">
            <div class="d-flex justify-between mt-10 mb-20">
                <span class="ubuntu-medium font-16">
                  Коды на ленте
                </span>
              <div class="d-flex">
                <span class="mr-10 ubuntu-medium font-14 text-gray">Показать:</span>
                <el-dropdown
                        id="innerCodes"
                        placement="bottom-start"
                        @visible-change="innerDropdown"
                        @command="selectFilter"
                        trigger="click">
                      <span class="el-dropdown-link">
                        {{innerFilter.label}}
                      </span>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="item in filters" :key="item.value" :command="['innerCodes', item]">
                      <span class="item">{{item.label}}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <inner-chart :amount="innerCodes.amount" :time="innerCodes.time"></inner-chart>
          </div>
          <div class="right-chart">
            <div class="d-flex justify-between align-center mt-10 mb-20">
                <span class="ubuntu-medium font-16">
                  ТОП 5 <span class="text-gray">ПО РЕГИОНАМ</span>
                </span>
              <div class="d-flex">
                <span class="mr-10 ubuntu-medium font-14 text-gray">Показать:</span>
                <el-dropdown
                        id="doughnutChart"
                        placement="bottom-start"
                        @visible-change="doughnutDropdown"
                        @command="selectFilter"
                        trigger="click">
                      <span class="el-dropdown-link">
                        {{doughnutFilter.label}}
                      </span>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="item in filters" :key="item.value" :command="['doughnutChart', item]">
                      <span class="item">{{item.label}}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <doughnut-chart :chart-data="doughnutChart"></doughnut-chart>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
//import regionMap from '../../components/analytics/regionMap'
import outerChart from '../../components/analytics/outerCodes'
import innerChart from '../../components/analytics/innerCodes'
import doughnutChart from '../../components/analytics/doughnutChart'

export default {
  name: "index",
  data () {
    return {
      dataTable: [],
      outerCodes: {
        amount: [],
        time: []
      },
      innerCodes: {
        amount: [],
        time: []
      },
      doughnutChart: [],
      mapChart: [],
      mapFilter: { value:'week', label: 'Неделя' },
      outerFilter: { value:'week', label: 'Неделя' },
      innerFilter: { value:'week', label: 'Неделя' },
      doughnutFilter: { value:'week', label: 'Неделя' },
      filters: [
        { value: 'week', label: 'Неделя' },
        { value: 'month', label: 'Месяц' },
        { value: 'quarter', label: 'Квартал' },
        { value: 'year', label: 'Год' },
      ],

      myMap: null,
    }
  },
  methods: {
    tableHeader () {
      return 'secondary-table-header'
    },
    timeZone (time) {
      time = new Date(time).getTimezoneOffset()
      time /= 60
      if (time % 1 != 0) {
        time = Math.trunc(time)
      }
      if (time >= 0) {
        time = '-' + time + ':00'
      } else {
        time = '+' + Math.abs(time) + ':00'
      }
      return time
    },
    mapDropdown (open) {
      if (open) {
        document.querySelector('#scanMap .el-dropdown-link').classList.add('open')
        setTimeout(()=>{
          let dropdown = document.querySelectorAll('.el-dropdown-menu')
          dropdown.forEach(value => {
            let top = value.getBoundingClientRect().top - 55
            let left = value.getBoundingClientRect().left - 20
            value.style.top = top + 'px'
            value.style.left = left + 'px'
            value.style.width = 200 + 'px'
          })
        },20)
      } else {
        document.querySelector('#scanMap .el-dropdown-link').classList.remove('open')
      }
    },
    outerDropdown (open) {
      if (open) {
        document.querySelector('#outerCodes .el-dropdown-link').classList.add('open')
        setTimeout(()=>{
          let dropdown = document.querySelectorAll('.el-dropdown-menu')
          dropdown.forEach(value => {
            let top = value.getBoundingClientRect().top - 55
            let left = value.getBoundingClientRect().left - 20
            value.style.top = top + 'px'
            value.style.left = left + 'px'
            value.style.width = 200 + 'px'
          })
        },20)
      } else {
        document.querySelector('#outerCodes .el-dropdown-link').classList.remove('open')
      }
    },
    innerDropdown (open) {
      if (open) {
        document.querySelector('#innerCodes .el-dropdown-link').classList.add('open')
        setTimeout(()=>{
          let dropdown = document.querySelectorAll('.el-dropdown-menu')
          dropdown.forEach(value => {
            let top = value.getBoundingClientRect().top - 55
            let left = value.getBoundingClientRect().left - 20
            value.style.top = top + 'px'
            value.style.left = left + 'px'
            value.style.width = 200 + 'px'
          })
        },20)
      } else {
        document.querySelector('#innerCodes .el-dropdown-link').classList.remove('open')
      }
    },
    doughnutDropdown (open) {
      if (open) {
        document.querySelector('#doughnutChart .el-dropdown-link').classList.add('open')
        setTimeout(()=>{
          let dropdown = document.querySelectorAll('.el-dropdown-menu')
          dropdown.forEach(value => {
            let top = value.getBoundingClientRect().top - 55
            let left = value.getBoundingClientRect().left - 20
            value.style.top = top + 'px'
            value.style.left = left + 'px'
            value.style.width = 200 + 'px'
          })
        },20)
      } else {
        document.querySelector('#doughnutChart .el-dropdown-link').classList.remove('open')
      }
    },
    assignGraphData(data, type) {
      data.reverse()
      if (type === 'doughnutChart') {
        this.$data[type] = []
        this.$data[type] = data
      } else if(type === 'mapChart') {
          let t = this;
          let update_func = function() {
              console.log("map update");
              let myMap = t.myMap;
              myMap.geoObjects.removeAll();

              let getPointData = function (d) {
                  let contentbody = `<p>${d.region}</p>`;
                  if(d.region != d.city) {
                      contentbody += `<p>${d.city}</p>`;
                  }
                  return {
balloonContentHeader: `Сканирований: ${d.count}`,
                          iconContent: `${d.count}`,
                          balloonContentBody: contentbody,
                          balloonContentFooter: '',
                  };
              }
              let getPointOptions = function () {
                  return {
                      //                preset: 'islands#violetIcon'
preset: 'islands#blueStretchyIcon',
                  };
              }
              let geoObjects = [];

              data.forEach((value, i) => {
                      geoObjects[i] = new ymaps.Placemark([value.latitude, value.longitude], getPointData(value), getPointOptions());
                      myMap.geoObjects.add(geoObjects[i]);
                      });
          };

          if(this.myMap) {
              update_func();
          } else {
              setTimeout(update_func, 3000);
          }
      } else {
        this.$data[type].amount = []
        this.$data[type].time = []
        data.forEach(value => {
          this.$data[type].amount.push(value['codes_qty'])
          this.$data[type].time.push(value.created)
        })
      }
    },
    async requestGraphData(filter) {
      let url
      switch (true) {
        case filter === 'doughnutChart':
          url = `/v1/factory/analytics/top?range=${this.doughnutFilter.value}`
          break
        case filter === 'outerCodes':
          url = `/v1/factory/analytics/usage?range=${this.outerFilter.value}&type=code128`
          break
        case filter === 'innerCodes':
          url = `/v1/factory/analytics/usage?range=${this.innerFilter.value}&type=datamatrix`
          break
        case filter === 'mapChart':
          url = `/v1/factory/analytics/map?range=${this.mapFilter.value}`
          break
      }
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          method: 'GET',
          url: url,
        })
        this.assignGraphData(resp.data.list, filter)
      } catch(e) {
        console.log(e)
      }
    },
    selectFilter(filter) {
      switch(true) {
        case filter[0] === 'outerCodes':
          this.outerFilter.value = filter[1].value
          this.outerFilter.label = filter[1].label
          break
        case filter[0] === 'innerCodes':
          this.innerFilter.value = filter[1].value
          this.innerFilter.label = filter[1].label
          break
        case filter[0] === 'doughnutChart':
          this.doughnutFilter.value = filter[1].value
          this.doughnutFilter.label = filter[1].label
          break
        case filter[0] === 'mapChart':
          this.mapFilter.value = filter[1].value
          this.mapFilter.label = filter[1].label
          break
        default:
        return
      }
      this.requestGraphData(filter[0])
    }
  },
  async created () {
    this.$store.commit('setPreloader', true)
    try {
      let resp = await this.$store.dispatch('backoffRequest', {
        method: 'GET',
        url: '/v1/factory/analytics/latest',
      })
      let doughnut = await this.$store.dispatch('backoffRequest', {
        method: 'GET',
        url: `/v1/factory/analytics/top?range=${this.doughnutFilter.value}`
      })
      let outer = await this.$store.dispatch('backoffRequest', {
        method: 'GET',
        url: `/v1/factory/analytics/usage?range=${this.outerFilter.value}&type=code128`
      })
      let inner = await this.$store.dispatch('backoffRequest', {
        method: 'GET',
        url: `/v1/factory/analytics/usage?range=${this.innerFilter.value}&type=datamatrix`
      })
      let map = await this.$store.dispatch('backoffRequest', {
        method: 'GET',
        url: `/v1/factory/analytics/map?range=${this.mapFilter.value}`
      })
      this.dataTable = resp.data.list
      this.assignGraphData(doughnut.data.list, 'doughnutChart')
      this.assignGraphData(outer.data.list, 'outerCodes')
      this.assignGraphData(inner.data.list, 'innerCodes')
      this.assignGraphData(map.data.list, 'mapChart')
      this.$store.commit('setPreloader', false)
    } catch (e) {
      console.log(e)
    }
  },
  beforeRouteLeave(to, from, next) {
    let tooltip = document.querySelector('.jvectormap-tip')
    if (tooltip) tooltip.remove()
    next()
  },

  updated() {
      let t = this;

      setTimeout(() => {
      console.log("updated");
      if(t.myMap) { return; }
      ymaps.ready(() => {
            console.log("map ready");
            t.myMap = new ymaps.Map("ymap", {
                center: [64, 90],
                zoom: 3,
            });
        });
      }, 1000);
  },

  components: {
//    regionMap,
    outerChart,
    innerChart,
    doughnutChart
  }
}
</script>
