<template>
  <div class="cable-property">
    <div class="d-flex type">
		<span class="ubuntu-regular font-14 mr-20">{{$t('production.prop.data_type')}}:</span>
      <div class="ubuntu-medium font-14">{{getType(file.type)}}</div>
    </div>
    <div class="d-flex title">
		<span class="ubuntu-regular font-14 mr-20">{{$t('production.prop.title')}}:</span>
      <div class="ubuntu-medium font-14 nowrap ellipsis">{{file.title}}</div>
    </div>
    <div class="d-flex link">
      <a :href="file.link" target="_blank" class="btn transparent floating red">
		  {{$t('production.prop.view')}}
	  </a>
    </div>
    <div v-if="status === 'new'" class="d-flex remove">
      <div @click="removeProp">
        <img class="control-icon" src="../../assets/images/production/trash.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "cableProperty",
    props: ['file', 'status'],
    methods: {
      getType(type) {
		  return this.$t('production.prop.types.'+type);
      },
      async removeProp() {
        if (confirm(this.$t('production.prop.delete_prop_dialog'))) {
          try {
            let resp = await this.$store.dispatch('backoffRequest', {
              url: `/v1/factory/products/resource/${this.$route.params.id}`,
              method: 'DELETE',
              data: {
                link: this.file.link
              }
            })
            if (resp.data.result) {
              this.$store.dispatch('showNotice', {
                title: this.$t('common.attention'),
                icon: 'warning',
                message: this.$t('production.prop.prop_deleted'),
                time: 4000
              })
              this.$emit('remove')
            }
          } catch(e) {
            console.log(e)
          }
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .cable-property {
    transition: all linear .2s;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #e3e7ed;
  }
  a.btn {
    text-decoration: none;
  }
  .type {
    margin-right: 30px;
    max-width: 200px;
    width: 100%;
  }
  .title {
    overflow: hidden;
    width: 100%;
  }
  .link {
    max-width: 150px;
    width: 100%;
  }
  .remove {
    max-width: 30px;
    width: 100%;
    justify-content: flex-end;
  }
  .control-icon {
    transition: all linear .2s;
    position: relative;
    bottom: 0;
    cursor: pointer;
    max-width: 22px;
    max-height: 22px;
    width: 100%;
    height: 100%;
    &:hover {
      bottom: 2px;
    }
  }
  .btn.secondary {
    border: none;
    color: #ffffff;
    background: #23282D;
    &:hover {
      color: #ffffff;
      border: none;
      box-shadow: 0 4px 10px rgba(35,40,45, 0.4);
    }
  }
</style>
