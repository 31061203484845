const state = {
  open: false,
  message: '',
  title: '',
  icon: '',
  timeOut: ''
}
const getters = {
  isNoticeOpen (state) {
    return state.open
  },
  getNoticeTitle (state) {
    return state.title
  },
  getNoticeMessage (state) {
    return state.message
  },
  getNoticeIcon (state) {
    return state.icon
  }
}
const mutations = {
  showNotice (state) {
    state.open = true
  },
  setNoticeMessage (state, message) {
    state.message = message
  },
  setNoticeIcon (state, icon) {
    switch (true) {
      case icon === 'warning':
        state.icon = require('../../assets/images/components/notice/alert.svg')
    }
  },
  setNoticeTitle (state, title) {
    state.title = title
  },
  setNoticeTimeout (state, time) {
    setTimeout(() => {
      state.open = false
      state.title = ''
      state.message = ''
      state.icon = ''
      state.timeOut = ''
    }, time)
  }
}
const actions = {
  showNotice ({ state, commit }, payload) {
    if (state.timeOut) {
      clearTimeout(state.timeOut)
    }
    commit('setNoticeTitle', payload.title)
    commit('setNoticeMessage', payload.message)
    commit('setNoticeIcon', payload.icon)
    commit('showNotice')
    commit('setNoticeTimeout', payload.time || 2000)
  },
  closeNotice ({ state, commit }) {
    if (state.timeOut) {
      clearTimeout(state.timeOut)
    }
    commit('setNoticeTimeout', 0)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
