import Axios from 'axios'

const state = {
  username: null,
  factoryName: null,
  factoryId: null,
  permissions: [],
  balance: null,
  token: null
}
const getters = {
  getToken: state => state.token,
  getBalance: state => state.balance,
  isAuth: state => !!state.token,
  getFactoryName: state => state.factoryName,
  getFactoryId: state => state.factoryId,
  getUserName: state => state.username,
  getPermission: (state) => (permission) => {
    return state.permissions.includes(permission)
  }
}
const mutations = {
  setFactoryName: (state, factory) => {
    state.factoryName = factory
  },
  setFactoryId: (state, factoryId) => {
	state.factoryId = factoryId;
  },
  setUserName: (state, name) => {
    state.username = name
  },
  setPermissions: (state, permissions) => {
    state.permissions = permissions
  },
  setBalance: (state, balance) => {
    state.balance = balance
  },
  setToken: (state, token) => {
    state.token = token
    localStorage.setItem('integrity.cabinet.token', token)
    Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  },
  logout: (state) => {
    state.token = null
    state.name = null
    state.factory = null
    state.permissions = []
    Axios.defaults.headers.common['Authorization'] = ''
    localStorage.removeItem('integrity.cabinet.token')
  }
}
const actions = {}

export default {
  state,
  getters,
  mutations,
  actions
}
