<template>
  <div class="unauth">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "unauthLayout"
}
</script>
