<template>
  <div class="sidebar">
    <router-link :to="{name: 'ProductionList'}" class="logo-wrapper">
      <img class="logo" src="../../assets/images/layout/sidebar/logo_red.svg" alt="" />
      <img class="logo small" src="../../assets/images/layout/sidebar/logo_red_small.svg" alt="" />
    </router-link>
    <div class="nav-wrapper">
      <router-link :to="{name: 'ProductionList'}" class="nav-item" tag="div" v-if="$store.getters.getPermission('products')">
        <img src="../../assets/images/layout/sidebar/production.svg" alt="" />
        <span>
			{{$t('layout.sidebar.products')}}
        </span>
      </router-link>
      <router-link :to="{name: 'Analytics'}" class="nav-item" tag="div" v-if="$store.getters.getPermission('analytics')">
        <img src="../../assets/images/layout/sidebar/analytics.svg" alt="" />
        <span>
			{{$t('layout.sidebar.analytics')}}
        </span>
      </router-link>
      <router-link :to="{name: 'Finance'}" class="nav-item" tag="div" v-if="$store.getters.getPermission('finances')">
        <img src="../../assets/images/layout/sidebar/finance.svg" alt="" />
        <span>
			{{$t('layout.sidebar.finances')}}
		</span>
      </router-link>
      <router-link :to="{name: 'Advert'}" class="nav-item" tag="div" v-if="$store.getters.getPermission('advert')">
        <img src="../../assets/images/layout/sidebar/commercial.svg" alt="" />
        <span>
			{{$t('layout.sidebar.advert')}}
		</span>
      </router-link>
      <router-link :to="{name: 'UserList'}" class="nav-item" tag="div" v-if="$store.getters.getPermission('users')">
        <img src="../../assets/images/layout/sidebar/users.svg" alt="" />
        <span>
			{{$t('layout.sidebar.users')}}
		</span>
      </router-link>
      <router-link :to="{name: 'CodeSearch'}" class="nav-item" tag="div" v-if="$store.getters.getPermission('products')">
        <img src="../../assets/images/layout/sidebar/barcode.svg" alt="" />
        <span>
			{{$t('layout.sidebar.code_search')}}
		</span>
      </router-link>

      <div class="divider"></div>
      <router-link :to="{name: 'Settings'}" class="nav-item" tag="div">
        <img src="../../assets/images/layout/sidebar/settings.svg" alt="" />
        <span>
			{{$t('layout.sidebar.settings')}}
		</span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "sidebar"
}
</script>
