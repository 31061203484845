<template>
  <div class="advert">
    <transition name="fade" mode="out-in">
      <div v-if="$store.getters.getPreloaderStatus" key="preloader" id="loadingProgressG">
        <div class="loadingProgressG"></div>
      </div>
      <el-row v-else key="content" :gutter="20">
        <el-col :sm="24" :md="12">
          <div class="panel mb-20">
            <div class="d-flex align-center">
              <div class="switcher-wrapper mr-10">
                <input id="switch" type="checkbox" v-model="advert.status" />
                <label @click="toggleAdvert" for="switch"></label>
              </div>
              <div class="text-red ubuntu-medium">
				  <label class="pointer" @click="toggleAdvert"
						 for="switch">{{$t('advert.advert_block')}}</label>
              </div>
            </div>
            <div class="mt-20">
              <textarea class="text-area" maxlength="180" rows="5" v-model="advert.message"></textarea>
			  <div class="text-right ubuntu-regular
						  font-15">{{$t('advert.symbols_used')}} {{ textAreaLenght }}/180</div>
            </div>
            <div class="mb-20 mt-20 d-flex">
              <div class="description">
				  {{$t('advert.advert_link')}}:
                <br />
				<span class="text-gray">({{$t('advert.optional')}})</span>
              </div>
              <div class="value input-wrapper">
                <input
                        :placeholder="$t('advert.paste_link')"
                        class="input" v-model="advert.link" />
              </div>
            </div>
            <button
                    @click="setAdvert"
					class="btn floating red">{{$t('common.save')}}</button>
          </div>
          <div class="panel">
            <div class="d-flex justify-between mt-10 mb-20">
                <span class="ubuntu-medium font-16">
					{{$t('advert.ad_view_graph')}}
                </span>
              <div class="d-flex">
				  <span class="mr-10 ubuntu-medium font-14
							   text-gray">{{$t('advert.view_range')}}:</span>
                <el-dropdown
                        id="historyChart"
                        placement="bottom-start"
                        @visible-change="historyDropdown"
                        @command="selectFilter"
                        trigger="click">
                      <span class="el-dropdown-link">
                        {{currentFilter.label}}
                      </span>
                  <el-dropdown-menu>
                    <el-dropdown-item v-for="item in filters" :key="item.value" :command="item">
                      <span class="item" v-if="currentFilter.label !== item.label">{{item.label}}</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <outer-graph :time="chart.time" :amount="chart.amount"></outer-graph>
          </div>
        </el-col>
        <el-col :sm="24" :md="12">
          <div class="panel flat h-100">
            <el-table :data="table"
			   :empty-text="$t('advert.no_data')"
			   :header-row-class-name="tableHeader">
              <el-table-column :label="$t('advert.date')" min-width="140">
                <template slot-scope="data">
                    <span class="ubuntu-regular text-black">
                      {{data.row ? new Date(data.row.timestamp).toLocaleDateString().split('.').reverse().join('-')  : ''}}
                    </span>
                </template>
              </el-table-column>
              <el-table-column :label="$t('advert.views')" min-width="100" prop='watches'></el-table-column>
              <el-table-column :label="$t('advert.clicks')" min-width="100" prop="clicks"></el-table-column>
              <el-table-column :label="$t('advert.conversion_percent')" min-width="150" align="center">
                <template slot-scope="data">
                  {{data.row ? data.row.rate : ''}} %
                </template>
              </el-table-column>
            </el-table>
            <div class="table-footer" v-if="pagination.total > 15">
              <div class="font-14 ubuntu-regular text-gray">
                {{ currentOffset }} of {{pagination.total}}
              </div>
              <el-pagination
                      :background="false"
                      :page-size="pagination.perPage"
                      :total="pagination.total"
                      :current-page="pagination.current"
                      layout="prev, next"
                      @current-change="changePage"></el-pagination>
            </div>
          </div>
        </el-col>
      </el-row>
    </transition>
  </div>
</template>

<script>
import outerGraph from '../../components/analytics/outerCodes'
export default {
  name: "index",
  data () {
    return {
      advert: {
        message: '',
        link: '',
        status: false
      },
      chart: {
        time: [],
        amount: []
      },
      table: [],
      pagination: {
        current: 1,
        perPage: 15,
        total: 16
      },
      currentFilter: { value:'week', label: this.$t('advert.week') },
      filters: [
        { value: 'week', label: this.$t('advert.week') },
        { value: 'month', label: this.$t('advert.month') },
        { value: 'quarter', label: this.$t('advert.quarter') },
        { value: 'year', label: this.$t('advert.year') },
      ],
    }
  },
  computed: {
    textAreaLenght: function () {
      return this.advert.message.length
    },
    currentOffset: function () {
      let start = (this.pagination.current - 1) * this.pagination.perPage + 1
      let end = ''
      if (this.pagination.current * this.pagination.perPage < this.pagination.total) {
        end = '- ' + this.pagination.current * this.pagination.perPage
      } else if (this.table.length < this.pagination.perPage && this.table.length > 1) {
        end = '- ' + this.pagination.total
      }
      return `${start} ${end}`
    }
  },
  watch: {
    advert: {
      deep: true,
      handler () {
        this.advert.message = this.advert.message.replace('\n', '')
      }
    }
  },
  methods: {
    tableHeader () {
      return 'secondary-table-header'
    },
    historyDropdown (open) {
      if (open) {
        document.querySelector('#historyChart .el-dropdown-link').classList.add('open')
        setTimeout(()=>{
          let dropdown = document.querySelectorAll('.el-dropdown-menu')
          dropdown.forEach(value => {
            let top = value.getBoundingClientRect().top - 55
            let left = value.getBoundingClientRect().left - 20
            value.style.top = top + 'px'
            value.style.left = left + 'px'
            value.style.width = 140 + 'px'
          })
        },20)
      } else {
        document.querySelector('#historyChart .el-dropdown-link').classList.remove('open')
      }
    },
    selectFilter(filter) {
      this.currentFilter.value = filter.value
      this.currentFilter.label = filter.label
      this.getChartData()
    },
    changePage (page) {
      this.pagination.current = page
      this.getTableData()
    },
    async getChartData () {
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          method: 'GET',
          url: `/v1/factory/advert/history?range=${this.currentFilter.value}`
        })
        this.chart.time = []
        this.chart.amount = []
        resp.data.reverse().forEach(value => {
          this.chart.time.push(value.time)
          this.chart.amount.push(value.count)
        })
      } catch (e) {
        console.log(e)
      }
    },
    async toggleAdvert () {
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          method: 'POST',
          url: '/v1/factory/advert/toggle'
        })
        this.$store.dispatch('showNotice', {
          title: this.$t('common.attention'),
          icon: 'warning',
          message: this.$t('common.data_saved'),
          time: 4000
        })
      } catch(e) {
        console.log(e)
      }
    },
    async getTableData () {
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          method: 'GET',
          url: '/v1/factory/advert/conversion',
          params: {
            limit: this.pagination.perPage,
            page: this.pagination.current - 1
          }
        })
        this.table = resp.data.result
        this.pagination.total = resp.data.total
      } catch(e) {
        console.log(e)
      }
    },
    async setAdvert () {
      let data = {}
      if (this.advert.message) {
        data.message = this.advert.message
      }
      if (this.advert.link) {
        data.link = this.advert.link
      }
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          method: 'POST',
          url: '/v1/factory/advert/set',
          data: data
        })
        this.$store.dispatch('showNotice', {
          title: this.$t('common.attention'),
          message: this.$t('common.changes_saved'),
          icon: 'warning',
          time: 4000
        })
      } catch (e) {
        console.log(e)
      }
    },
    async getAdvert () {
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          method: 'GET',
          url: '/v1/factory/advert/settings'
        })
        this.advert.message = resp.data.advert.message
        this.advert.link = resp.data.advert.link
        this.advert.status = resp.data.enabled
      } catch(e) {
        console.log(e)
      }
    }
  },
  async created () {
    this.getChartData()
    this.getTableData()
    this.getAdvert()
  },
  components: {
    outerGraph
  }
}
</script>
