<template>
	<el-select :class="['auth-locale-selector', (dark ? 'dark' : ''),
		(hideNames ? 'hide-names' : '')]"
			   v-model="$i18n.locale" placeholder="Language">
		<template slot="prefix">
			<div class="language-selector-flag">
				<img :src="require(`../assets/images/components/language_selector/${$i18n.locale}_flag.png`)" />
			</div>
		</template>

		<el-option
				v-for="lang in $i18n.langs"
				:key="lang.id"
				:label="lang.l_name"
				:value="lang.id">
			<div class="language-selector-row">
				<div style="float: left">
					<img :src="require(`../assets/images/components/language_selector/${lang.id}_flag.png`)" />
				</div>
				<span style="float: left" v-if="!hideNames">{{ lang.l_name }}</span>
			</div>
		</el-option>
	</el-select>
</template>

<script>
import i18n from "../i18n";

export default {
	name: 'languageSelector',
	props: ["hideNames", "dark"],
};
</script>
