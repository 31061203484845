import VueI18n from "vue-i18n"
import Vue from 'vue'
import lang_ru from 'element-ui/lib/locale/lang/ru-RU'
import lang_en from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

Vue.use(VueI18n)

let current_locale = localStorage.getItem("integrity.cabinet.locale");
if(!current_locale) {
	current_locale = navigator.language.split("-")[0] || "en";
}

function loadLocaleMessages() {
	const locales = require.context(
		"./locales",
		true,
		/[A-Za-z0-9-_,\s]+\.json$/i
	);
	const messages = {};
	locales.keys().forEach(key => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages;
}
const i18n = new VueI18n({
	locale: current_locale,
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
	messages: loadLocaleMessages()
});

i18n.saveLocale = (l) => {
	current_locale = l;
	i18n.locale = l;
	localStorage.setItem("integrity.cabinet.locale", l);
};

i18n.langs = [
	{id: "en", eng_name: "English", l_name: "English", },
	{id: "ru", eng_name: "English", l_name: "Русский", },
	{id: "zh", eng_name: "Chinese", l_name: "中文", },
];

if(i18n.locale === 'ru') {
	locale.use(lang_ru);
} else {
	locale.use(lang_en);
}

export default i18n;
