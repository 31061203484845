<template>
  <div class="notification-wrapper">
    <div class="cross" @click="$store.dispatch('closeNotice')"></div>
    <div class="notification-body">
      <div class="icon">
        <img :src="$store.getters.getNoticeIcon" alt="">
      </div>
      <div class="notification-text">
        <span class="title">{{ $store.getters.getNoticeTitle }}</span>
        <br>
        <span class="description">{{ $store.getters.getNoticeMessage }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notification'
}
</script>
