<template>
  <div class="user-create">
	  <div class="user-title">{{$t('users.user_editing')}}</div>
    <div class="user-wrapper">
      <div class="data">
        <div class="form-group">
          <div class="description">{{$t('users.name_abbreviated')}}</div>
          <div class="input-wrapper">
            <input
                   :placeholder="$t('users.enter_name_desc')"
                   class="input" v-model="user.fio"/>
          </div>
        </div>
        <div class="form-group"  v-if="user.role.value === 'factory_admin'">
          <div class="description">E-mail</div>
          <div class="input-wrapper">
            <input
                   :placeholder="$t('users.enter_email_desc')"
                   class="input" v-model="user.email"/>
          </div>
        </div>
        <div class="form-group">
			<div class="description">{{$t('users.field_headers.role')}}</div>
          <div class="input-wrapper">
            <input class="input" v-model="user.role.label" readonly />
          </div>
        </div>
        <div class="form-group">
          <div class="description">
			{{$t('users.password')}}
          </div>
          <div class="input-wrapper" >
            <input v-if="user.role.value === 'otk'" readonly v-model="user.password" class="text-red ubuntu-medium input" />
            <input v-else v-model="user.password" class="text-red ubuntu-medium input" />
          </div>
        </div>
        <div class="form-group">
          <div class="text-red pointer ubuntu-medium font-14" @click="passGen">
              {{$t('users.generate_new_password')}}
		  </div>
        </div>
        <div class="d-flex">
          <div class="switcher-wrapper mr-10">
            <input id="switch" type="checkbox" v-model="user.blocked" />
            <label for="switch"></label>
          </div>
          <div class="text-gray ubuntu-medium">
            <transition mode="out-in" name="fade">
			<span v-if="user.blocked" key="unblock">{{$t('users.unblock')}}</span>
			<span v-else key="block">{{$t('users.block')}}</span>
            </transition>
          </div>
        </div>
      </div>
      <transition mode="out-in" name="fade">
        <div v-if="user.role.value === 'factory_admin'" class="checkboxes">
			<span class="ubuntu-medium font-16">{{$t('users.access_permissions')}}:</span>
          <div v-if="$store.getters.getPermission('analytics')" class="d-flex mt-20">
            <div class="checkbox mr-10">
              <input id="analytics" type="checkbox" v-model="rights.analytics" class="display-none">
              <label for="analytics"></label>
            </div>
			<label class="pointer" for="analytics">{{$t('users.permissions.analytics')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('advert')" class="d-flex mt-5">
            <div class="checkbox mr-10">
              <input id="advertising" type="checkbox" v-model="rights.advert" class="display-none">
              <label class="pointer" for="advertising"></label>
            </div>
            <label for="advertising">{{$t('users.permissions.advertisements')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('users')" class="d-flex mt-5">
            <div class="checkbox mr-10">
              <input id="users" type="checkbox" v-model="rights.users" class="display-none">
              <label for="users"></label>
            </div>
            <label class="pointer" for="users">{{$t('users.permissions.users')}}</label>
          </div>

          <div v-if="$store.getters.getPermission('products')" class="d-flex mt-5">
            <div class="checkbox mr-10">
              <input id="production" type="checkbox" v-model="rights.products" class="display-none">
              <label for="production"></label>
            </div>
            <label class="pointer" for="production">{{$t('users.permissions.products')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('products.edit')" class="d-flex mt-5 ml-20">
              <div class="checkbox mr-10">
                  <input id="production_edit" type="checkbox" v-model="rights.productsEdit" class="display-none">
                  <label for="production_edit"></label>
              </div>
              <label class="pointer" for="production_edit">{{$t('users.permissions.products_edit')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('products.release')" class="d-flex mt-5 ml-20">
              <div class="checkbox mr-10">
                  <input id="production_release" type="checkbox" v-model="rights.productsRelease" class="display-none">
                  <label for="production_release"></label>
              </div>
              <label class="pointer" for="production_edit">{{$t('users.permissions.products_release')}}</label>
          </div>

          <div v-if="$store.getters.getPermission('finances')" class="d-flex mt-5">
            <div class="checkbox mr-10">
              <input id="finance" type="checkbox" v-model="rights.finances" class="display-none">
              <label for="finance"></label>
            </div>
            <label class="pointer" for="finance">{{$t('users.permissions.finances')}}</label>
          </div>
          <div v-if="$store.getters.getPermission('finance-report')" class="d-flex ml-20 mt-5">
            <div class="checkbox mr-10">
              <input id="acts" type="checkbox" v-model="rights.financeReport" class="display-none">
              <label for="acts"></label>
            </div>
            <label class="pointer" for="acts">{{$t('users.permissions.reconciliation_report')}}</label>
          </div>
        </div>
      </transition>
      <div class="buttons">
        <button class="btn transparent red nowrap mt-10 cancel"
				@click="removeUser">{{$t('users.remove_user')}}</button>
        <button class="btn transparent red mt-10"
			@click="cancel">{{$t('common.cancel')}}</button>
        <button class="btn floating red mt-10"
			@click="changeUser">{{$t('users.save_changes')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import pc from 'password-creator'
  export default {
    name: "read",
    data () {
      return {
        user: {
          fio: null,
          email: null,
          role: { label: null, value: null },
          password: "********",
          status: false
        },
        rights: {
          analytics: false,
          advert: false,
          users: false,
          products: false,
          finances: false,
          financeReport: false,
          productsEdit: false,
          productsRelease: false,
        },
        dropdown: [
          { label: this.$t('users.roles.factory_admin'), value: 'factory_admin' },
          { label: this.$t('users.roles.otk'), value: 'otk' }
        ]
      }
    },
    watch: {
      'rights.financeReport': function (val) {
        if (val) {
          this.rights.finances = true
        }
      },
      'rights.finances': function (val) {
        if (!val) {
          this.rights.financeReport = false
        }
      },
      'rights.products': function(val) {
          if(!val) {
              this.rights.productsEdit = false;
              this.rights.productsRelease = false;
          }
      },
      'rights.productsEdit': function(val) {
          if(val)
              this.rights.products = true;
      },
      'rights.productsRelease': function(val) {
          if(val)
              this.rights.products = true;
      },
    },
    methods: {
      passGen () {
        let settings
        if (this.user.role.value === 'factory_admin' ) {
          settings = {
            sets: {
              letters: true,
              lowercase: true,
              uppercase: false,
              digit: true,
              special: false,
              space: false
            },
            length: 8
          }
        } else {
          settings = {
            sets: {
              letters: false,
              lowercase: true,
              uppercase: false,
              digit: true,
              special: false,
              space: false
            },
            length: 4
          }
        }
        this.user.password = pc.create(settings)
      },
      cancel() {
        this.$router.push({name: 'UserList'})
      },
      async removeUser () {
        if (confirm(this.$t('users.dialog_delete_user'))) {
          try {
            let resp = await this.$store.dispatch('backoffRequest', {
              url: `/v1/factory/users/${this.$route.params.id}`,
              method: 'DELETE'
            })
            if(resp.data.result) {
              this.$router.push({name: 'UserList'})
            }
          } catch (e) {
            console.log(e)
          }
        }
      },
      async changeUser () {
        // check data
        // FIXME should show server-side check result
        let err = null;
        if(this.user.role.value !== 'otk' && this.user.password.length < 8) {
            err = this.$t('users.minimal_password_length_desc');
        }
        if(err != null) {
          this.$store.dispatch('showNotice', {
            title: this.$t('common.save_error'),
            message: this.$t('common.save_error') + ": " + err,
            icon: 'warning',
            time: 5000
          })
          return;
        }

        let data
        switch(true) {
          case this.user.role.value === 'factory_admin':
            data = {
              fio: this.user.fio,
              email: this.user.email,
              permissions: [],
              status: this.user.blocked ? 'blocked' : 'active',
            }
            if (this.user.password !== '********') {
              data.password = this.user.password
            }
            // TODO bidirectional mapping table
            Object.keys(this.rights).forEach(permission => {
              if (this.rights[permission]) {
                if (permission === 'financeReport') {
                  data.permissions.push('finance-report')
                } else if(permission === "productsEdit") {
                  data.permissions.push('products.edit')
                } else if(permission === "productsRelease") {
                  data.permissions.push('products.release')
                } else {
                  data.permissions.push(permission)
                }
              }
            })
            break
          case this.user.role.value === 'otk':
            data = {
              fio: this.user.fio,
              pin: this.user.password,
              status: this.user.blocked ? 'blocked' : 'active'
            }
            break
        }
        try {
          await this.$store.dispatch('backoffRequest', {
            url: `v1/factory/users/${this.$route.params.id}`,
            method: 'PUT',
            data: data
          })
          this.$store.dispatch('showNotice', {
            title: this.$t('common.success'),
            message: this.$t('users.user_modified'),
            icon: 'warning',
            time: 4000
          })
        } catch(e) {
          console.log(e)
          this.$store.dispatch('showNotice', {
            title: this.$t('common.save_error'),
            message: this.$t('common.save_error'),
            icon: 'error',
            time: 5000
          })
        }
      }
  },
    async created() {
      try {
        let resp = await this.$store.dispatch('backoffRequest', {
          url: `/v1/factory/users/${this.$route.params.id}`,
          method: 'GET'
        })
        this.user.fio = resp.data.info.fio
        if (resp.data.info.status === 'blocked') {
          this.user.blocked = true
        }
        switch (true) {
          case resp.data.info.role === 'factory_admin':
            this.user.email = resp.data.info.email
            this.user.role = {label: this.$t('users.roles.factory_admin'), value: 'factory_admin'}
            // TODO bidirectional mapping table
            resp.data.info.permissions.forEach(permission => {
              if (permission === 'finance-report') {
                this.rights.financeReport = true
              } else if(permission === "products.edit") {
                this.rights.productsEdit = true
              } else if(permission === "products.release") {
                this.rights.productsRelease = true
              } else {
                this.rights[permission] = true
              }
            })
            break
          case resp.data.info.role === 'otk':
            this.user.password = resp.data.info.pin
            this.user.role = { label: this.$t('users.roles.otk'), value: 'otk'}
            break
        }
      } catch(e) {
        console.log(e)
      }
    }
  }
</script>
