<template>
  <div class="content">
    <transition mode="out-in" name="fade">
      <div v-if="$store.getters.getPreloaderStatus" key="preloader" id="loadingProgressG">
        <div class="loadingProgressG"></div>
      </div>
      <div v-else class="production" key="content">
        <div class="table mt-10">
			<el-button v-if="haveFilters"
					   @click="clearFilters">{{$t('common.clear_filters')}}</el-button>

          <el-table
                  :data="tableData"
				  :empty-text="$t('common.list_is_empty')"
				  :header-row-class-name="tableHeader"
				  height="100%"
			  >
			<el-table-column type="index" :index="(pagination.perPage*(pagination.current-1)+1)" width="50">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
				<img alt="" class="download-xlsx-btn"
					:title="$t('common.download_xlsx')"
					src="../../assets/images/production/download_xlsx.svg"
					width="32px" height="32px"
					@click="downloadXLSX"
				 />
				</template>
			</el-table-column>
            <el-table-column id="col_date" ref="col_date"
				:label="$t('production.field_headers.date_created')" min-width="180">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
                    <el-dropdown id="date_filter" placement="bottom-start"
                            trigger="click" :hide-on-click="false">
						<span>{{$t('production.field_headers.date_created')}}
                            <i :class="['el-select__caret', 'el-input__icon', 'el-icon-arrow-down']"></i>
                            <span class="caret-wrapper" @click="handleSortClick('date')">
                                <i v-if="sort.date==1" class="isort-caret ascending_selected"/>
                                <i v-else class="isort-caret ascending"/>
                                <i v-if="sort.date==-1" class="isort-caret descending_selected"/>
                                <i v-else class="isort-caret descending"/>
                            </span>
                        </span>
                        <el-dropdown-menu>
                            <el-dropdown-item>
        		    			<el-date-picker
                                    size="mini"
                                    v-model="dateFilter"
                                    type="daterange"
                                    :start-placeholder="$t('common.date_begin')"
                                    :end-placeholder="$t('common.date_end')"
                                    :picker-options="pickerOptions"
                                    @input="filter"
                                    @click="dropdownClick"
                                />
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
				</template>
              <template slot-scope="data">
                <router-link :to="{name: 'ProductionRead', params: {id: data.row.uuid}}" tag="a" class="cable-name">
                  <span class="ubuntu-regular text-black">
                    {{data.row ? new Date(data.row.created).toLocaleDateString().split('.').reverse().join('-')  : ''}}
                  </span>
                  <span class="ubuntu-regular text-gray">
                    {{data.row ? new Date(data.row.created).toLocaleTimeString().split(':').slice(0,2).join(':') : ''}}
                    ({{ timeZone(data.row ? data.row.created : '') }})
                  </span>
                </router-link>
              </template>
            </el-table-column>

            <el-table-column id="col_date_released" ref="col_date_released"
				:label="$t('production.field_headers.released_time')" min-width="180">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
                    <el-dropdown id="released_time_filter" placement="bottom-start"
                            trigger="click" :hide-on-click="false">
						<span>{{$t('production.field_headers.date_released')}}
                            <i :class="['el-select__caret', 'el-input__icon', 'el-icon-arrow-down']"></i>
                            <span class="caret-wrapper" @click="handleSortClick('released_time')">
                                <i v-if="sort.released_time==1" class="isort-caret ascending_selected"/>
                                <i v-else class="isort-caret ascending"/>
                                <i v-if="sort.released_time==-1" class="isort-caret descending_selected"/>
                                <i v-else class="isort-caret descending"/>
                            </span>
                        </span>
                        <el-dropdown-menu>
                            <el-dropdown-item>
        		    			<el-date-picker
                                    size="mini"
                                    v-model="releasedTimeFilter"
                                    type="daterange"
                                    :start-placeholder="$t('common.date_begin')"
                                    :end-placeholder="$t('common.date_end')"
                                    :picker-options="pickerOptions"
                                    @input="filter"
                                    @click="dropdownClick"
                                />
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
				</template>
              <template slot-scope="data">
                  <span class="ubuntu-regular text-black" v-if="data.row.released_time">
                    {{data.row ? new Date(data.row.released_time).toLocaleDateString().split('.').reverse().join('-')  : ''}}
                  </span>
                  <span class="ubuntu-regular text-gray" v-if="data.row.released_time">
                    {{data.row ? new Date(data.row.released_time).toLocaleTimeString().split(':').slice(0,2).join(':') : ''}}
                    ({{ timeZone(data.row ? data.row.created : '') }})
                  </span>
              </template>
            </el-table-column>

			<el-table-column :label="$t('production.field_headers.drum_number')" min-width="90">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
                    <el-dropdown id="drum_filter" placement="bottom-start"
                            trigger="click" :hide-on-click="false">
						<span>{{$t('production.field_headers.drum_number')}}
                            <i :class="['el-select__caret', 'el-input__icon', 'el-icon-arrow-down']"></i>
                            <span class="caret-wrapper" @click="handleSortClick('drum_number')">
                                <i v-if="sort.drum_number==1" class="isort-caret ascending_selected"/>
                                <i v-else class="isort-caret ascending"/>
                                <i v-if="sort.drum_number==-1" class="isort-caret descending_selected"/>
                                <i v-else class="isort-caret descending"/>
                            </span>
                        </span>
                        <el-dropdown-menu>
                            <el-dropdown-item>
        		    			<el-input
                                    v-model="drumFilter"
                                    :placeholder="$t('production.fields.drum_number')"
                                    clearable
                                    @change="filter"
                                />
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
				</template>
			  <template slot-scope="data">
			  	<span class="ubuntu-regular test-black">
					{{data.row ? data.row.drum_number : ''}}
				</span>
			  </template>
			</el-table-column>

			<el-table-column :label="$t('production.field_headers.comment')" min-width="90">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
                    <el-dropdown id="comment" placement="bottom-start"
                            trigger="click" :hide-on-click="false">
						<span>{{$t('production.field_headers.comment')}}
                            <i :class="['el-select__caret', 'el-input__icon', 'el-icon-arrow-down']"></i>
                        </span>
                        <el-dropdown-menu>
                            <el-dropdown-item>
        		    			<el-input
                                    v-model="commentFilter"
                                    :placeholder="$t('production.fields.comment')"
                                    clearable
                                    @change="filter"
                                />
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
				</template>
			  <template slot-scope="data">
			  	<span class="ubuntu-regular test-black">
					{{data.row ? data.row.comment: ''}}
				</span>
			  </template>
			</el-table-column>

            <el-table-column :label="$t('production.field_headers.length')" min-width="90">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
                    <el-dropdown id="length_filter" placement="bottom-start"
                            trigger="click" :hide-on-click="false">
						<span>{{$t('production.field_headers.length')}}
                            <i :class="['el-select__caret', 'el-input__icon', 'el-icon-arrow-down']"></i>
                            <span class="caret-wrapper" @click="handleSortClick('length')">
                                <i v-if="sort.length==1" class="isort-caret ascending_selected"/>
                                <i v-else class="isort-caret ascending"/>
                                <i v-if="sort.length==-1" class="isort-caret descending_selected"/>
                                <i v-else class="isort-caret descending"/>
                            </span>
                        </span>
                        <el-dropdown-menu>
                            <el-dropdown-item>
        		    			<el-input
                                    v-model="lengthFilterMin"
                                    :placeholder="$t('production.filters.length_begin')"
                                    clearable
                                    @change="filter"
                                    type="number"
                                />
                            </el-dropdown-item>
                            <el-dropdown-item>
        		    			<el-input
                                    v-model="lengthFilterMax"
                                    :placeholder="$t('production.filters.length_end')"
                                    clearable
                                    @change="filter"
                                    type="number"
                                />
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
				</template>
              <template slot-scope="data">
                <span class="ubuntu-regular text-black">{{data.row ?  data.row.length : ''}} {{$t('common.meters_letter')}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('production.field_headers.num_codes')" min-width="100">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
                    <el-dropdown id="num_codes_filter" placement="bottom-start"
                            trigger="click" :hide-on-click="false">
						<span>{{$t('production.field_headers.num_codes')}}
                            <i :class="['el-select__caret', 'el-input__icon', 'el-icon-arrow-down']"></i>
                            <span class="caret-wrapper" @click="handleSortClick('num_codes')">
                                <i v-if="sort.num_codes==1" class="isort-caret ascending_selected"/>
                                <i v-else class="isort-caret ascending"/>
                                <i v-if="sort.num_codes==-1" class="isort-caret descending_selected"/>
                                <i v-else class="isort-caret descending"/>
                            </span>
                        </span>
                        <el-dropdown-menu>
                            <el-dropdown-item>
        		    			<el-input
                                    v-model="numCodesFilterMin"
                                    :placeholder="$t('production.filters.num_codes_begin')"
                                    clearable
                                    @change="filter"
                                    type="number"
                                />
                            </el-dropdown-item>
                            <el-dropdown-item>
        		    			<el-input
                                    v-model="numCodesFilterMax"
                                    :placeholder="$t('production.filters.num_codes_end')"
                                    clearable
                                    @change="filter"
                                    type="number"
                                />
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
				</template>
              <template slot-scope="data">
                <span class="ubuntu-regular text-black">{{data.row ? data.row['codes_qty'] : ''}}</span>
              </template>
            </el-table-column>
            <el-table-column :label="$t('production.fields.type')" align="center" min-width="100">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
					<el-select v-model="cableTypeFiltersValue" multiple
							:placeholder="$t('production.fields.type')"
							@change="filter">
						<el-option
								v-for="item in cableTypeFilters"
								:key="item.value"
								:label="item.label"
								:value="item.value">
						</el-option>
					</el-select>
                 </template>
              <template slot-scope="data">
                <span class="ubuntu-regular text-black">{{data.row ? data.row.type : ''}}</span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('production.field_headers.files')" align="center" min-width="150">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
					<el-select v-model="cableHaveCertFiltersValue" multiple
							:placeholder="$t('production.field_headers.files')"
							@change="filter">
						<el-option
								v-for="item in cableHaveCertFilters"
								:key="item.value"
								:label="item.label"
								:value="item.value">
						</el-option>
					</el-select>
				</template>

              <template slot-scope="data">
                <div v-if="data.row" class="d-flex align-center justify-center">
					<img v-if="data.row.have_cert" class="mr-10" src="../../assets/images/production/document.svg"
						:title="$t('production.cert_attached')" alt=""/>
					<img v-else class="mr-10" src="../../assets/images/production/document_empty.svg" alt="" />

					<img v-if="iconConversion(data.row.files, 'image')" class="mr-10"
						src="../../assets/images/production/image.svg" alt=""
						:title="$t('production.images_attached')"/>
					<img v-else class="mr-10" src="../../assets/images/production/image_empty.svg" alt=""/>

					<img v-if="iconConversion(data.row.files, 'video')" class="mr-10"
						src="../../assets/images/production/video.svg" alt=""
						:title="$t('production.videos_attached')"/>
					<img v-else class="mr-10" src="../../assets/images/production/video_empty.svg" alt=""/>

					<img v-if="iconConversion(data.row.files, 'doc')" src="../../assets/images/production/attachment.svg" alt=""
						:title="$t('production.docs_attached')"/>
					<img v-else src="../../assets/images/production/attachment_empty.svg" alt=""/>
                </div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('production.fields.status')" header-align="center" min-width="200">
				<!-- eslint-disable-next-line vue/no-unused-vars -->
				<template slot="header" slot-scope="scope">
					<el-select v-model="cableStatusFiltersValue" multiple
							:placeholder="$t('production.fields.status')"
							@change="filter">
						<el-option
								v-for="item in cableStatusFilters"
								:key="item.value"
								:label="item.label"
								:value="item.value">
						</el-option>
					</el-select>
				</template>
				<!-- eslint-disable-line vue/no-unused-vars -->
              <template slot-scope="data">
                <div class="d-flex flex-column align-center">
                  <span :class="[data.row && data.row.status === 'released' ? 'text-red' : '']"
                    class="ubuntu-medium">{{data.row ? statusConversion(data.row.status) : ''}}
                  </span>
                  <span class="ubuntu-regular text-gray nowrap"
                        v-if="data.row && data.row.user">({{ nameConversion(data.row.user.fio) }})</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-footer" v-if="pagination.total > pagination.perPage">
          <div class="font-14 ubuntu-regular text-gray">
            {{ currentOffset }} of {{pagination.total}}
          </div>
          <el-pagination
                  :background="false"
                  :page-size="pagination.perPage"
                  :total="pagination.total"
                  :current-page="pagination.current"
                  layout="prev, next"
                  @current-change="changePage"></el-pagination>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: "index",

    data () {
      return {
        pagination: {
          current: 1,
          perPage: 50,
          total: 0
        },
        filterStorageName: "INTEGRITY_PRODUCTS_FILTERS",
        allCables: false,
        released: false,
		only_show_new_cables: false,
        tableData: [],
		cableStatusFiltersValue: [],
		cableStatusFilters: [
			{ value: 'new', label: this.$t('production.status.new') },
			{ value: 'released', label: this.$t('production.status.released') },
			{ value: 'checking', label: this.$t('production.status.checking') },
		],
        dateFilter: [],
		releasedTimeFilter: [],
        drumFilter: "",
        commentFilter: "",
        lengthFilterMin: "",
        lengthFilterMax: "",
        numCodesFilterMin: "",
        numCodesFilterMax: "",
        cableTypeFiltersValue: [],
        cableTypeFilters: [
            { value: 'code128', label: 'code128' },
            { value: 'datamatrix', label: 'datamatrix' },
			{ value: 'multiset', label: 'multiset' },
        ],
        cableHaveCertFiltersValue: [],
        cableHaveCertFilters: [
            { value: true, label: this.$t('production.filters.cert_attached') },
            { value: false, label: this.$t('production.filters.cert_not_attached') },
        ],
        haveFilters: false,

        sort: {
            date: 0,
			released_time: 0,
            drum_number: 0,
            length: 0,
            num_codes: 0,
        },

        pickerOptions: {
            firstDayOfWeek: 1,
        },
      }
    },
    computed: {
      currentOffset: function () {
        let start = (this.pagination.current - 1) * this.pagination.perPage + 1
        let end = ''
        if (this.pagination.current * this.pagination.perPage < this.pagination.total) {
          end = '- ' + this.pagination.current * this.pagination.perPage
        } else if (this.tableData.length < this.pagination.perPage && this.tableData.length > 1) {
          end = '- ' + this.pagination.total
        }
        return `${start} ${end}`
      },
    },
    methods: {
      tableHeader () {
        return 'secondary-table-header'
      },
      async changePage (page) {
          this.$router.replace({
              name: this.$route.name,
              query: {
                  page_number: page,
              },
          });
          this.filter(true);
          return;
      },
      timeZone (time) {
        time = new Date(time).getTimezoneOffset()
        time /= 60
        if (time % 1 != 0) {
          time = Math.trunc(time)
        }
        if (time >= 0) {
          time = '-' + time + ':00'
        } else {
          time = '+' + Math.abs(time) + ':00'
        }
        return time
      },
      iconConversion (data, type) {
        if (!data) {
          return false
        }
        let flag = false
        data.forEach(value => {
          if (value.type === type ) {
            flag = true
          }
        })
        return flag
      },
      statusConversion (data) {
        switch (true) {
          case data === 'new':
			return this.$t('production.status.new');
          case data === 'released':
			return this.$t('production.status.released');
		  case data === 'checking':
			return this.$t('production.status.checking');
		  case data === 'deleted':
			return this.$t('production.status.deleted');
          default:
            return data
        }
      },
      nameConversion (name) {
        // TODO: remove this when fix backend
        if (!name) {
          name = ''
        }
        let arr = name.split(' ')
        arr.forEach((value, index, arr) => {
          if (index >= 1) {
            arr[index] = value[0] + '.'
          }
        })
        return arr.join(' ')
      },

		extractFilterParams() {
            let status = (this.cableStatusFiltersValue.length>0)
                ? this.cableStatusFiltersValue
                : this.cableStatusFilters.map(function(i){return i.value;});
            let type = (this.cableTypeFiltersValue.length>0)
                ? this.cableTypeFiltersValue
                : this.cableTypeFilters.map(function(i){return i.value;});
            const drum_number = this.drumFilter.length > 0 ? this.drumFilter : null;
            const comment = this.commentFilter.length > 0 ? this.commentFilter : null;
            const length = (this.lengthFilterMin != 0 || this.lengthFilterMax != 0)
                ? [this.lengthFilterMin || 0, this.lengthFilterMax || 0]
                : null;
            const num_codes = (this.numCodesFilterMin != 0 || this.numCodesFilterMax != 0)
                ? [this.numCodesFilterMin, this.numCodesFilterMax]
                : null;
            const have_cert = (this.cableHaveCertFiltersValue.length==1)
                ? this.cableHaveCertFiltersValue[0]
                : null;
			const date = this.dateFilter;
			const released_time = this.releasedTimeFilter;

            let sort = [];
            const s = this.sort;
            const sort_keys = ["date", "released_time", "drum_number", "length", "num_codes"];
            sort_keys.forEach((k) => {
                if(s[k] != 0) {
                    sort.push(k);
                    sort.push((s[k] == 1) ? "asc" : "desc");
                }
            });

			return {
				status, date, released_time, type, drum_number, length,
				num_codes, have_cert, sort, comment,
			};
		},

        async filter(first_filter=false) {
            this.updateHaveFilters();

            this.pagination.current = (first_filter &&
                this.$route.query.page_number) ? +this.$route.query.page_number
            : 1;
            if(this.pagination.current != this.$route.query.page_number) {
                this.$router.replace({
                    name: this.$route.name,
                    query: {
                        page_number: this.pagination.current,
                    },
                });
            }

            const url = `/v1/factory/products?page=${this.pagination.current - 1}&limit=${this.pagination.perPage}`;
			const f = this.extractFilterParams();

            try {
                const resp = await this.$store.dispatch('backoffRequest', {
                    url: url,
                    method: 'GET',
                    params: {
                        status: f.status,
                        date: f.date,
						released_time: f.released_time,
                        drum_number: f.drum_number,
						comment: f.comment,
                        length: f.length,
                        num_codes: f.num_codes,
                        type: f.type,
                        have_cert: f.have_cert,
                        sort: f.sort,
                    }
                })

                if(resp.data.cables.length === 0 && resp.data.total > 0) {
                    // page is out of range, restart with page 1
                    return await this.filter(false);
                }
                this.pagination.total = resp.data.total

                // extract comment from properties
                for(const c of resp.data.cables) {
                    if(!c.product_properties) { continue; }
                    for(const p of c.product_properties) {
                        if(p.property_name === "comment") {
                            c.comment = p.property_value_text;
                            break;
                        }
                    }
                }

                this.tableData = resp.data.cables
            } catch (e) {
                console.log(e)
            }
        },

        dropdownClick(event) {
            event.stopPropagation();
        },

        handleSortClick(name) {
            function wraparound(v) {
                if(v == 0) { return -1; }
                else if(v == -1) { return 1; }
                else { return 0; }
            }

            event.stopPropagation();

            this.sort[name] = wraparound(this.sort[name]);

            this.filter();
        },

        updateHaveFilters() {
            this.haveFilters = this.cableStatusFiltersValue.length > 0 ||
                    this.dateFilter.length > 0 ||
					this.releasedTimeFilter.length > 0 ||
                    this.drumFilter != "" ||
                    this.commentFilter != "" ||
                    this.lengthFilterMin != "" ||
                    this.lengthFilterMax != "" ||
                    this.numCodesFilterMin != "" ||
                    this.numCodesFilterMax != "" ||
                    this.cableTypeFiltersValue.length > 0 ||
                    this.cableHaveCertFiltersValue.length > 0 ||
                    Object.keys(this.sort).map((k) => { if(this.sort[k] != 0)
                        return this.sort[k]; }).filter((i) => i != undefined).length > 0;
            this.saveFilters();
        },

        clearFilters() {
		    this.cableStatusFiltersValue = [];
            this.dateFilter = [];
			this.releasedTimeFilter = [];
            this.drumFilter = "";
            this.commentFilter = "";
            this.lengthFilterMin = "";
            this.lengthFilterMax = "";
            this.numCodesFilterMin = "";
            this.numCodesFilterMax = "";
            this.cableTypeFiltersValue = [];
            this.cableHaveCertFiltersValue = [];
            this.sort = {
                date: 0,
				released_time: 0,
                drum_number: 0,
                length: 0,
                num_codes: 0,
            };
            this.filter();
        },

        saveFilters() {
            const filters = {
                cableStatusFiltersValue: this.cableStatusFiltersValue,
                dateFilter: this.dateFilter,
                drumFilter: this.drumFilter,
                commentFilter: this.commentFilter,
                lengthFilterMin: this.lengthFilterMin,
                lengthFilterMax: this.lengthFilterMax,
                numCodesFilterMin: this.numCodesFilterMin,
                numCodesFilterMax: this.numCodesFilterMax,
                cableTypeFiltersValue: this.cableTypeFiltersValue,
                cableHaveCertFiltersValue: this.cableHaveCertFiltersValue,
                sort: {
                    date: this.sort.date,
					released_time: this.sort.released_time,
                    drum_number: this.sort.drum_number,
                    length: this.sort.length,
                    num_codes: this.sort.num_codes,
                },
            };
            localStorage.setItem(this.filterStorageName,
                JSON.stringify(filters));
        },
        loadFilters() {
            try {
                const filters = JSON.parse(localStorage.getItem(this.filterStorageName));
                this.cableStatusFiltersValue = filters.cableStatusFiltersValue;
                this.dateFilter = filters.dateFilter;
                this.drumFilter = filters.drumFilter;
                this.commentFilter = filters.commentFilter;
                this.lengthFilterMin = filters.lengthFilterMin;
                this.lengthFilterMax = filters.lengthFilterMax;
                this.numCodesFilterMin = filters.numCodesFilterMin;
                this.numCodesFilterMax = filters.numCodesFilterMax;
                this.cableTypeFiltersValue = filters.cableTypeFiltersValue;
                this.cableHaveCertFiltersValue = filters.cableHaveCertFiltersValue;
                this.sort = {
                    date: filters.sort.date,
					released_time: filters.sort.released_time,
                    drum_number: filters.sort.drum_number,
                    length: filters.sort.length,
                    num_codes: filters.sort.num_codes,
                };
                this.filter(true);
            } catch(e) {
                console.error("can't load saved filters");
                this.clearFilters();
            }
        },

		async downloadXLSX() {
            const url = "/v1/factory/products/xlsx";
			const f = this.extractFilterParams();

            try {
                const resp = await this.$store.dispatch('backoffRequest', {
                    url: url,
                    method: 'GET',
                    params: {
                        status: f.status,
                        date: f.date,
						released_time: f.released_time,
                        drum_number: f.drum_number,
                        length: f.length,
                        num_codes: f.num_codes,
                        type: f.type,
                        have_cert: f.have_cert,
                        sort: f.sort,
                    },
					responseType: "blob",
                });

				const element = document.createElement("a");
				element.href = URL.createObjectURL(resp.data);
				element.setAttribute("download", "products.xlsx");
				element.style.display = "none";
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
            } catch (e) {
                console.log(e)
            }
		},
    },
    async created () {
      this.$store.commit('setPreloader', true);

      try {
        this.loadFilters();
        this.$store.commit('setPreloader', false)
      } catch (e) {
        console.log(e)
      }
    },
  }
</script>

<style lang="scss" scoped>
  button.btn {
    transition: all linear .15s;
    line-height: 1;
    font-size: 13px;
    height: 25px;
    width: 100%;
    max-width: 120px;
  }
</style>
