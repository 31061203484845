import Axios from 'axios'
import i18n from "../../i18n";

const state = {
  preloader: false
}
const getters = {
  getPreloaderStatus: state => state.preloader
}
const mutations = {
  setPreloader (state, status) {
    state.preloader = status
  }
}
const actions = {
  backoffRequest: ({dispatch}, req) => {
    let request = req
    request.i = Object.prototype.hasOwnProperty.call(request, 'i') ? request.i + 1 : 0;
    let resp
    return new Promise(async (resolve, reject) => {
      try {
        resp = await Axios({
          url: request.url,
          method: request.method,
          data: request.data,
          params: {
			  lang: i18n.locale,
			  ...request.params
		  },
          headers: request.headers,
		  responseType: request.responseType,
        })
        resolve(resp)
      } catch(e) {
        if (request.i <= 4 && (e.message && e.message === 'Network Error' || e.response && e.response.status >= 500)) {
          setTimeout(async () => {
            try {
              resp = await dispatch('backoffRequest', request)
              resolve(resp)
            } catch (e) {
              reject(e)
            }
          },1000 * Math.pow(2, request.i))
        } else {
          reject(e)
        }
      }
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
