<template>
  <div class="cable-property-edit">
    <div class="d-flex type">
		<span class="ubuntu-medium font-14 mr-20">{{$t('production.prop.data_type')}}:</span>
      <el-dropdown
              id="typeDropdown"
              placement="bottom-start"
              @visible-change="dropdownToggle"
              @command="selectType"
              trigger="click">
                      <span class="el-dropdown-link ">
                        {{type.label}}
                      </span>
        <el-dropdown-menu>
          <el-dropdown-item v-for="item in select" :key="item.value" :command="item">
            <span class="item" v-if="item.label !== type.label">{{item.label}}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="d-flex title">
		<span class="ubuntu-medium font-14 mr-20">{{$t('production.prop.title')}}:</span>
      <div class="input-wrapper">
        <input
                :placeholder="$t('production.prop.enter_title')"
                v-model="title" class="input" type="text" />
      </div>
    </div>
    <div class="d-flex link">
      <div class="input-wrapper">
        <input
                :placeholder="$t('production.prop.paste_link')"
                class="input" type="text" v-model="url" />
        <div class="helper-wrapper">
			<span>{{$t('production.prop.size_limit')}}</span>
        </div>
      </div>
      <input @change="updateFile" class="d-none" type="file" id="fileHolder"/>
      <button v-if="!(type.value === 'video')" @click="selectFile" class="btn floating secondary ml-10">
		  {{$t('production.prop.select')}}
      </button>
    </div>
    <div class="d-flex buttons">
      <div @click="saveProperty" class="mr-10">
        <img class="control-icon desktop" src="../../assets/images/production/check.svg" alt="" />
        <div class="btn red floating mobile">
			{{$t('common.save')}}
        </div>
      </div>
      <div @click="$emit('close')">
        <img class="control-icon desktop" src="../../assets/images/production/trash.svg" alt="" />
        <div class="btn transparent secondary floating mobile">
			{{$t('common.cancel')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "cablePropertyEdit",
    data() {
      return {
        type: { value: 'doc', label: this.$t('production.prop.types.doc') },
        title: '',
        select: [
          { value: 'doc', label: this.$t('production.prop.types.doc') },
          { value: 'image', label: this.$t('production.prop.types.image') },
          { value: 'video', label: this.$t('production.prop.types.video') }
        ],
        url: null,
        file: null
      }
    },
    watch: {
      url: function (value) {
        if(this.file && (value === this.file.name) || !this.file) return
        this.file = null
        this.url = null
        this.$store.dispatch('showNotice', {
          title: this.$t('common.attention'),
          icon: 'warning',
          message: this.$t('production.prop.attached_file_deleted'),
          time: 4000
        })
      }
    },
    methods: {
      dropdownToggle (open) {
        if (open) {
          document.querySelector('#typeDropdown .el-dropdown-link').classList.add('open')
          setTimeout(()=>{
            let dropdown = document.querySelectorAll('.el-dropdown-menu')
            dropdown.forEach(value => {
              let top = value.getBoundingClientRect().top - 55
              let left = value.getBoundingClientRect().left - 20
              value.style.top = top + 'px'
              value.style.left = left + 'px'
              value.style.width = 150 + 'px'
            })
          },20)
        } else {
          document.querySelector('#typeDropdown .el-dropdown-link').classList.remove('open')
        }
      },
      selectType(item) {
        this.type.label = item.label
        this.type.value = item.value
        this.title = ''
        this.file = null
        this.url = null
      },
      // file logic
      selectFile() {
        document.querySelector('#fileHolder').click()
      },
      updateFile(e) {
        let file = e.target.files || e.dataTransfer.files
        if (!file.length) return
        this.file = file[0]
        this.url = file[0].name
      },
      resetProperty () {
        this.type = { value: 'doc', label: this.$t('production.prop.types.doc') }
        this.title = ''
        this.url = null
        this.file = null
      },
      // save Property
      async saveProperty() {
        switch (true) {
          case !!this.file:
            let formData = new FormData()
            formData.set('cable_id', this.$route.params.id)
            formData.set('title', this.title)
            formData.set('type', this.type.value)
            formData.set('file', this.file)
            try {
              let resp = await this.$store.dispatch('backoffRequest', {
                url: '/v1/factory/products/resource/file',
                method: 'POST',
                data: formData,
                headers: {'Content-Type': 'multipart/form-data' }
              })
              if (!resp.data.status === 'ok') {
                throw(resp)
              }
              this.$store.dispatch('showNotice', {
                title: this.$t('common.attention'),
                icon: 'warning',
                message: this.$t('common.data_saved'),
                time: 4000
              })
              this.resetProperty()
              this.$emit('close')
            } catch (e) {
				// FIXME use error codes
              if (e.response && e.response.data.message ===
				  this.$t('production.prop.upload_error_msg')) {
                this.$store.dispatch('showNotice', {
                  title: this.$t('common.attention'),
                  icon: 'warning',
                  message: this.$t('production.prop.change_file_name'),
                  time: 4000
                })
              }
            }
            break
          default:
            try {
              let resp = await this.$store.dispatch('backoffRequest', {
                url: `/v1/factory/products/resource/link/${this.$route.params.id}`,
                method: 'POST',
                data: {
                  type: this.type.value,
                  title: this.title,
                  link: this.url
                },
              })
              if (resp.data.result) {
                this.$store.dispatch('showNotice', {
                  title: this.$t('common.attention'),
                  icon: 'warning',
                  message: this.$t('common.data_saved'),
                  time: 4000
                })
                this.resetProperty()
                this.$emit('close')
              } else {
                throw(resp)
              }
            } catch(e) {
              console.log(e)
            }
            break
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .cable-property-edit {
    transition: all linear .2s;
    display: flex;
    overflow: hidden;
    justify-content: space-between;
    align-items: center;
    height: 0;
    border-bottom: 1px solid #e3e7ed;
    &.open {
      height: 70px;
    }
    .link {
      margin-top: 20px;
    }
    .buttons {
      .mobile {
        display: none;
      }
      .desktop {
        display: block;
      }
    }
    @media screen and (max-width: 1050px) {
      align-items: flex-start;
      flex-direction: column;
      &.open {
        height: 155px;

      }
      .type {
        margin-top: 10px;
        margin-bottom: 10px;
      }
      .title {
        margin-bottom: 10px;
        width: 100%;
      }
      .link {
        width: 100%;
        margin-top: 0;
        margin-bottom: 10px;
      }
      .buttons {
        margin-bottom: 10px;
        width: 100%;
        justify-content: center;
        .desktop {
          display: none;
        }
        .mobile {
          display: flex;
        }
      }
    }
  }
  .el-dropdown {
    width: 100px;
    .el-dropdown-link {
      padding-left: 0;
    }
  }
  .input-wrapper .input {
    line-height: 1;
  }
  .input-wrapper .helper-wrapper {
    height: 20px;
    margin-top: 0;
    opacity: 1;
    font-style: italic;
    span {
      color: #B1B8C7;
    }
  }
  .control-icon {
    transition: all linear .2s;
    position: relative;
    bottom: 0;
    cursor: pointer;
    max-width: 22px;
    max-height: 22px;
    width: 100%;
    height: 100%;
    &:hover {
      bottom: 2px;
    }
  }
  .btn.secondary {
    border: none;
    color: #ffffff;
    background: #23282D;
    &:hover {
      color: #ffffff;
      border: none;
      box-shadow: 0 4px 10px rgba(35,40,45, 0.4);
    }
  }
</style>
