<template>
  <div class="code-search">
    <div v-if="$store.getters.getPreloaderStatus" key="preloader" id="loadingProgressG">
      <div class="loadingProgressG"></div>
    </div>
    <div v-else key="content" class="code-search">
      <form @submit.prevent="searchCode" id="codeSearchForm">
        <div class="form-group">
          <div class="description">
			  {{$t('code_search.desc')}}
          </div>
          <div class="input-wrapper">
            <input class="input"
                   required
                   :placeholder="$t('code_search.enter_code')" v-model="code_to_search" />
          </div>
        </div>
      </form>
      <div class="d-flex justify-between mt-20">
        <button type="submit" form="codeSearchForm" class="btn red floating">
			{{$t('code_search.search')}}
        </button>
      </div>

	  <div v-if="error">
		  <div class="code-not-found">
			  <span>{{errorDescription}}</span>
		  </div>
	  </div>

      <div class="search-result" v-if="response">
			<div v-if="response && response.code_found===false" class="code-not-found">
				<span>{{$t('code_search.code_not_found')}}</span><br/>
				<span v-if="response.code_type === 'code128'" class="code-search-error-text">
					{{$t('code_search.code_not_found_long_desc')}}
				</span>
				<span v-else class="code-search-error-text">
					{{$t('code_search.contact_support')}}
				</span>
			</div>

			<div v-if="!error && response && response.code_found" class="code-info-block">
				<div class="code-type">
					<span>{{$t('code_search.code_type')}}: {{response.code_type}}</span>
				</div>

				<div v-if="response.code_type === 'datamatrix'" class="code-date">
					<div v-if="response.factories && response.factories.length > 0">
						<span>{{$t('code_search.ribbon_date_imported')}}: {{formatDate(datamatrix_import_date)}}</span>
					</div>
				</div>
				<div v-if="response.code_type === 'code128'" class="code-date">
					<div v-if="response.factories && response.factories.length > 0">
						<span>{{$t('code_search.date_printed')}}: {{formatDate(code128_print_date)}}</span>
					</div>
				</div>
				<div v-if="!response.factories || response.factories.length === 0">
					<span>{{$t('code_search.code_unavailable')}}</span>
				</div>

				<div class="ribbon-connections-table-block"
						v-if="response.code_type === 'datamatrix' && ribbonConnectionsTableData && ribbonConnectionsTableData.length > 0">
					<div class="ribbon-connections-table">
						<span class="table-title">{{$t('code_search.ribbon_connection_list')}}</span>
						<el-table :data="ribbonConnectionsTableData" height="100%"
								:empty-text="$t('common.list_is_empty')">
							<el-table-column id="col_ribbon_date"
								ref="col_ribbon_date"
								:label="$t('code_search.ribbon_connection_date')" min-width="100">
								<template slot-scope="data">
									<span class="ubuntu-regular text-black">
										{{data.row ? formatDate(data.row.timestamp) : ''}}
									</span>
								</template>
							</el-table-column>
							<el-table-column id="col_ribbon_num_codes"
								ref="col_ribbon_num_codes"
								:label="$t('code_search.num_codes_in_ribbon_span')" min-width="120">
								<template slot-scope="data">
									<span class="ubuntu-regular text-black">
										{{data.row ? data.row.num_codes : ''}}
									</span>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>

				<div class="products-table-block" v-if="productTableData && productTableData.length > 0">
					<div class="products-table">
						<span class="table-title">{{$t('code_search.products')}}</span>
						<el-table :data="productTableData"
										 :empty-text="$t('common.list_is_empty')" height="100%">
							<!--<el-table-column type="index" :index="1" width="50" />-->
							<el-table-column id="col_date" ref="col_date"
								:label="$t('production.field_headers.date_created')" min-width="100">
								<template slot-scope="data">
									<span :class="['ubuntu-regular', data.row.status === 'released' ? 'text-green' : 'text-black']">
										<router-link :to="{name: 'ProductionRead', params: {id: data.row.uuid}}"
										  		tag="a" class="cable-name"
												target="_blank">
											<span class="ubuntu-regular text-black">
												{{data.row ? new Date(data.row.created_timestamp).toLocaleDateString().split('.').reverse().join('-')  : ''}}
											</span>
											<span class="ubuntu-regular text-gray">
												{{data.row ? new Date(data.row.created_timestamp).toLocaleTimeString().split(':').slice(0,2).join(':') : ''}}
												({{ timeZone(data.row ?  data.row.created_timestamp : '') }})
											</span>
										</router-link>
									</span>
								</template>
							</el-table-column>
							<el-table-column id="col_length" ref="col_length"
								:label="$t('production.fields.length')" min-width="70">
								<template slot-scope="data">
									<span :class="['ubuntu-regular', data.row.status === 'released' ? 'text-green' : 'text-black']">
										{{data.row ? data.row.length : ''}}
									</span>
								</template>
							</el-table-column>
							<el-table-column id="col_drum_number"
											 ref="col_drum_number" :label="$t('production.fields.drum_number')" min-width="90">
								<template slot-scope="data">
									<span :class="['ubuntu-regular', data.row.status === 'released' ? 'text-green' : 'text-black']">
										{{data.row ? data.row.drum_number : ''}}
									</span>
								</template>
							</el-table-column>
							<el-table-column id="col_status" ref="col_status"
								:label="$t('production.fields.status')" min-width="100">
								<template slot-scope="data">
									<span :class="['ubuntu-regular', data.row.status === 'released' ? 'text-green' : 'text-black']">
										{{data.row ?  formatStatus(data.row.status) : ''}}
									</span>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</div>
      </div>
    </div>
  </div>
</template>

<script>
const strftime = require("strftime");

export default {
    name: "index",
    data () {
        return {
			response: undefined,
			productTableData: [],
			datamatrix_import_date: "",
			code128_print_date: "",
			code: "",
			error: false,
			errorDescription: "",
            code_to_search: "",
        }
    },
    methods: {
        async searchCode() {
            this.$store.commit('setPreloader', true);

            this.error = false;
            this.response = undefined;
            try {
                const res = await this.$store.dispatch('backoffRequest', {
                    url: "/v1/factory/code/search",
                    method: 'GET',
                    params: {
                        code: this.code_to_search,
                    },
                });

				this.response = res.data;
				this.productTableData = res.data.products;
				if(res.data.code_type === "datamatrix") {
					this.datamatrix_import_date = "";
					if(res.data.factories && res.data.factories.length > 0) {
						this.datamatrix_import_date = res.data.factories[0].timestamp;
					}

					this.ribbonConnectionsTableData = [];
					if(res.data.ribbon_connections &&
						res.data.ribbon_connections.length > 0) {
						this.ribbonConnectionsTableData =
							res.data.ribbon_connections;
					}
				} else if(res.data.code_type === "code128") {
					this.code128_print_date = "";
					if(res.data.factories && res.data.factories.length > 0) {
						this.code128_print_date = res.data.factories[0].timestamp;
					}
				}
                this.code = this.code_to_search;
            } catch(e) {
				this.error = true;
				this.errorDescription = this.$t('code_search.check_error');
				if(e.type === "server-error") {
					this.errorDescription = (e.data && e.data.message)
						? e.data.message : e.message;
				} else if(e.type === "no-internet") {
					this.errorDescription = this.$t('common.no_internet');
				} else {
					this.errorDescription =
						this.$t('code_search.code_check_error') +": "+e.message;
				}
            }

            this.$store.commit('setPreloader', false);
        },

		formatDate(d) {
			if(!d) { return ""; }
			return strftime("%d.%m.%Y %R", new Date(d));
		},
		timeZone(time) {
			time = new Date(time).getTimezoneOffset()
			time /= 60
			if (time % 1 != 0) {
				time = Math.trunc(time)
			}
			if (time >= 0) {
				time = '-' + time + ':00'
			} else {
				time = '+' + Math.abs(time) + ':00'
			}
			return time
		},

		formatStatus(data) {
			return this.$t('production.status.'+data);
		},
    },

    created () {
        this.$store.commit('setPreloader', false);
    }
}
</script>
